import { $specShowSpilterator, $SVSplicer, $2SSplicer } from '@/erp/plugins/erp'
import { deepCopy } from 'kits'
import store from '@/store'

/**
 * 生成规格标签的工具函数，用于处理双规格（如颜色和尺寸）的情况
 * @param {string} type - 规格类型的英文名称，多个类型用逗号分隔，如 'Color,Size'
 * @param {string} typeCn - 规格类型的中文名称，多个类型用逗号分隔，如 '颜色,尺寸'
 * @param {string} value1 - 第一个规格的值（英文），多个值用逗号分隔
 * @param {string} value2 - 第二个规格的值（英文），多个值用逗号分隔
 * @param {string} valueCn1 - 第一个规格的值（中文），多个值用逗号分隔
 * @param {string} valueCn2 - 第二个规格的值（中文），多个值用逗号分隔
 * @returns {Object} 返回包含三种格式的规格标签：
 *   - label: 英文规格标签，格式为 'type1:value1$2SSplicer type2:value2'
 *   - labelCn: 中文规格标签，格式为 'typeCn1:valueCn1$2SSplicer typeCn2:valueCn2'
 *   - labelAll: 完整规格标签，包含中英文，使用 $specShowSpilterator 分隔
 */
const _getLabel = (type, typeCn, value1, value2, valueCn1, valueCn2) => {
  const types = type?.split(',') || []
  const typesCn = typeCn?.split(',') || []
  const values1 = value1?.split(',') || []
  const values2 = value2?.split(',') || []
  const valuesCn1 = valueCn1?.split(',') || []
  const valuesCn2 = valueCn2?.split(',') || []

  // 必要参数缺失时返回空值
  if (!types[0] || !types[1] || !values1[0] || !values2[0]) {
    return { label: '', labelCn: '', labelAll: '' }
  }

  const label = `${types[0]}:${values1[0]}${$2SSplicer}${types[1]}:${values2[0]}`
  const labelCn = `${typesCn[0] || ''}:${valuesCn1[0] || ''}${$2SSplicer}${
    typesCn[1] || ''
  }:${valuesCn2[0] || ''}`
  const labelAll = `${types[0]}${$specShowSpilterator}${typesCn[0] || ''}:${
    values1[0]
  }${$specShowSpilterator}${valuesCn1[0] || ''}，${
    types[1]
  }${$specShowSpilterator}${typesCn[1] || ''}:${
    values2[0]
  }${$specShowSpilterator}${valuesCn2[0] || ''}`

  return { label, labelCn, labelAll }
}
/**
 * 生成规格标签的工具函数，用于处理单规格和双规格的情况
 * @param {Object} specItem - 规格信息对象
 * @param {string} specItem.specsType - 规格类型（英文），单规格时为单个值，双规格时用逗号分隔，如 'Color' 或 'Color,Size'
 * @param {string} specItem.specsTypeCn - 规格类型（中文），单规格时为单个值，双规格时用逗号分隔，如 '颜色' 或 '颜色,尺寸'
 * @param {string} specItem.specsValue1 - 第一个规格的值（英文），如 'Red'
 * @param {string} specItem.specsValue2 - 第二个规格的值（英文），双规格时使用，如 'L'
 * @param {string} specItem.specsValueCn1 - 第一个规格的值（中文），如 '红色'
 * @param {string} specItem.specsValueCn2 - 第二个规格的值（中文），双规格时使用，如 '大号'
 * @returns {Object} 返回处理后的规格标签对象
 *   - label: 英文规格标签，单规格格式为 'type$SVSplicervalue'，双规格格式为 'type1:value1$2SSplicertype2:value2'
 *   - labelCn: 中文规格标签，格式同上
 *   - labelAll: 完整规格标签，包含中英文，使用 $specShowSpilterator 分隔
 */
export function getLabels(specItem) {
  // 解构规格信息对象，设置默认值为空字符串
  const {
    specsType = '',
    specsTypeCn = '',
    specsValue1 = '',
    specsValue2 = '',
    specsValueCn1 = '',
    specsValueCn2 = '',
  } = specItem || {}
  // 未开启规格
  if (!specsType) {
    return {
      label: '',
      labelCn: '',
      labelAll: '',
    }
  }
  // 判断是否为双规格（通过检查specsType中是否包含逗号）
  if (specsType?.includes(',')) {
    // 双规格情况：调用_getLabel处理双规格标签
    return _getLabel(
      specsType,
      specsTypeCn,
      specsValue1,
      specsValue2,
      specsValueCn1,
      specsValueCn2
    )
  } else {
    // 单规格情况：直接拼接规格类型和值
    return {
      label: specsType ? `${specsType}${$SVSplicer}${specsValue1 || ''}` : '', // 拼接的英文规格
      labelCn: specsTypeCn
        ? `${specsTypeCn}${$SVSplicer}${specsValueCn1 || ''}`
        : '', // 拼接的中文规格
      labelAll: specsType
        ? `${specsType}${$specShowSpilterator}${
            specsTypeCn || ''
          }${$SVSplicer}${specsValue1 || ''}${$specShowSpilterator}${
            specsValueCn1 || ''
          }`
        : '', // 全规格
    }
  }
}

export function getProcessedSpecData(specItem) {
  // 解构赋值，确保属性存在
  const {
    specsType1 = '',
    specsTypeCn1 = '',
    specsValue1 = '',
    specsValueCn1 = '',
    specsType2 = '',
    specsTypeCn2 = '',
    specsValue2 = '',
    specsValueCn2 = '',
  } = specItem || {}
  const specsInfo = {}

  // 只有当specsType1存在时才拼接分隔符
  specsInfo.specsValue1 = specsType1
    ? `${specsType1}${$SVSplicer}${specsValue1 || ''}`
    : ''
  specsInfo.specsValueCn1 = specsTypeCn1
    ? `${specsTypeCn1}${$SVSplicer}${specsValueCn1 || ''}`
    : ''

  if (specsType2) {
    specsInfo.specsValue2 = specsType2
      ? `${specsType2}${$SVSplicer}${specsValue2 || ''}`
      : ''
    specsInfo.specsValueCn2 = specsTypeCn2
      ? `${specsTypeCn2}${$SVSplicer}${specsValueCn2 || ''}`
      : ''
  }

  return specsInfo
}

/**
 * 拼接规格信息的工具函数，用于将规格的英文值和中文值组合成一个字符串
 * @param {Object} specItem - 规格信息对象
 * @param {string} specItem.specsValue1 - 第一个规格的英文值
 * @param {string} specItem.specsValue2 - 第二个规格的英文值（双规格时使用）
 * @param {string} specItem.specsValueCn1 - 第一个规格的中文值
 * @param {string} specItem.specsValueCn2 - 第二个规格的中文值（双规格时使用）
 * @returns {string} 返回拼接后的规格字符串
 *   - 单规格格式：'英文值/中文值'
 *   - 双规格格式：'英文值1$2SSplicer英文值2/中文值1$2SSplicer中文值2'
 *   - 当中文值不存在时，使用'--'代替
 */
export function joinSpecs(specItem) {
  const { specsValue1, specsValue2, specsValueCn1, specsValueCn2 } = specItem
  if (specsValue2) {
    // 双规格情况：使用$2SSplicer分隔符拼接两组规格值
    return `${specsValue1}${$2SSplicer}${specsValue2}/${
      specsValueCn1 || '--'
    }${$2SSplicer}${specsValueCn2 || '--'}`
  } else {
    // 单规格情况：直接用/分隔英文值和中文值
    return `${specsValue1}/${specsValueCn1 || '--'}`
  }
}

/**
 * 获取规格的英文值，支持单规格和双规格
 * @param {Object} spec - 规格信息对象
 * @param {string} spec.specsValue1 - 第一个规格的英文值
 * @param {string} [spec.specsValue2] - 第二个规格的英文值（双规格时使用）
 * @returns {string} 返回拼接后的规格英文值
 *   - 单规格格式：'specsValue1'
 *   - 双规格格式：'specsValue1$2SSplicerspecsValue2'
 */
export function getSpecsValue(spec) {
  const { specsValue1 = '', specsValue2 = '' } = spec || {}
  return specsValue1
    ? `${specsValue1}${specsValue2 ? $2SSplicer + specsValue2 : ''}`
    : ''
}

/**
 * 获取规格的中文值，支持单规格和双规格
 * @param {Object} spec - 规格信息对象
 * @param {string} spec.specsValueCn1 - 第一个规格的中文值
 * @param {string} [spec.specsValueCn2] - 第二个规格的中文值（双规格时使用）
 * @returns {string} 返回拼接后的规格中文值
 *   - 单规格格式：'specsValueCn1'
 *   - 双规格格式：'specsValueCn1$2SSplicerspecsValueCn2'
 */
export function getSpecsValueCn(spec) {
  const { specsValueCn1 = '', specsValueCn2 = '' } = spec || {}
  return specsValueCn1
    ? `${specsValueCn1}${specsValueCn2 ? $2SSplicer + specsValueCn2 : ''}`
    : ''
}

/**
 * 从配置列表中获取规格相关的配置项
 * @param {Array} enConfig - 配置项数组
 * @returns {Array|undefined} 返回规格相关的配置项数组，如果没有找到则返回undefined
 *   - 通过attributeName === '规格'进行过滤
 */
export function getSellerProductSpecsList(enConfig) {
  if (Array.isArray(enConfig)) {
    return enConfig.filter((config) => config.attributeName === '规格')
  }
}

/**
 * 将规格信息转换为完整的类型值规格对象
 * @param {Object} specInfo - 规格信息对象
 * @param {string} specInfo.specsType1 - 第一个规格类型（英文），如 'Color'
 * @param {string} specInfo.specsType2 - 第二个规格类型（英文），如 'Height'
 * @param {string} specInfo.specsTypeCn1 - 第一个规格类型（中文），如 '颜色'
 * @param {string} specInfo.specsTypeCn2 - 第二个规格类型（中文），如 '高度'
 * @param {string} specInfo.specsValue1 - 第一个规格值（英文），如 'Red7'
 * @param {string} specInfo.specsValue2 - 第二个规格值（英文），如 'Height1'
 * @param {string} specInfo.specsValueCn1 - 第一个规格值（中文），如 '红7'
 * @param {string} specInfo.specsValueCn2 - 第二个规格值（中文），如 '高度1'
 * @returns {Object} 返回处理后的规格对象
 *   - specsValue1: 英文规格值（单规格）或组合规格值（双规格）
 *   - specsValueCn1: 中文规格值（单规格）或组合规格值（双规格）
 */
export function getWholeTypeValueSpec(specInfo) {
  const {
    specsValue1 = '',
    specsValueCn1 = '',
    specsValue2 = '',
    specsValueCn2 = '',
  } = getProcessedSpecData(specInfo) || {}
  const wholeTypeValueSpec = {}

  if (specsValue2) {
    wholeTypeValueSpec.specsValue1 = specsValue1
      ? `${specsValue1}${$2SSplicer}${specsValue2 || ''}`
      : ''
    wholeTypeValueSpec.specsValueCn1 = specsValueCn1
      ? `${specsValueCn1}${$2SSplicer}${specsValueCn2 || ''}`
      : ''
  } else {
    wholeTypeValueSpec.specsValue1 = specsValue1 || ''
    wholeTypeValueSpec.specsValueCn1 = specsValueCn1 || ''
  }

  return wholeTypeValueSpec
}

/**
 * 根据英文规格类型名称获取对应的中文规格类型名称
 * @param {string} nameEn - 规格类型的英文名称
 * @returns {string} 返回对应的中文规格类型名称，如果未找到则返回空字符串
 */
export function getSellerSpecTypeCnWithEn(nameEn) {
  const { productSpecsList } = store.state.sellerProduct
  return (
    productSpecsList?.find((spec) => spec.attributeValueEn === nameEn)
      ?.attributeValue || ''
  )
}
/**
 * 根据规格类型和规格值的英文名称获取对应的中文规格值
 * @param {string} typeEn - 规格类型的英文名称，如 'Color'
 * @param {string} valueEn - 规格值的英文名称，如 'Red'
 * @returns {string} 返回对应的中文规格值，如果未找到则返回空字符串
 * @example
 * // 从productSpecsList中查找
 * // productSpecsList = [{
 * //   attributeValueEn: 'Color',
 * //   sellerChild: [{
 * //     attributeValueEn: 'Red',
 * //     attributeValue: '红色'
 * //   }]
 * // }]
 * getSellerSpecValueCnWithEn('Color', 'Red') // 返回 '红色'
 */
export function getSellerSpecValueCnWithEn(typeEn, valueEn) {
  if (!typeEn || !valueEn) {
    return ''
  }

  const { productSpecsList = [], specsList = [] } = store.state.sellerProduct

  // 首先从productSpecsList中查找
  const execSpecType = productSpecsList.find(
    (spec) => spec.attributeValueEn === typeEn
  )
  if (execSpecType?.sellerChild) {
    const execSpecValue = execSpecType.sellerChild.find(
      (spec) => spec.attributeValueEn === valueEn
    )
    if (execSpecValue?.attributeValue) {
      return execSpecValue.attributeValue
    }
  }

  // 如果在productSpecsList中未找到，则从specsList中查找
  const fallbackSpecType = specsList.find((spec) => spec.nameEn === typeEn)
  if (fallbackSpecType?.children) {
    const fallbackSpecValue = fallbackSpecType.children.find(
      (spec) => spec.nameEn === valueEn
    )
    if (fallbackSpecValue?.nameCn) {
      return fallbackSpecValue.nameCn
    }
  }

  // 如果都未找到，返回空字符串
  return ''
}

/**
 * 处理卖家产品保存和提交的参数
 * @param {Object} product - 产品信息对象
 * @param {Array} product.productSpecsAddPriceDTOList - 规格加价列表
 * @param {Array} product.surfaceDTOList - 表面处理列表
 * @param {Array} product.productSpecsDTOList - 产品规格列表
 * @param {string} product.specsType - 规格类型，多个类型用逗号分隔
 * @param {Array} productSpecsList - 产品规格配置列表
 * @returns {Object} 返回处理后的产品信息对象，包含补充的中文规格信息
 */
export function getSellerProductSaveParams(product, productSpecsList) {
  const productInfo = deepCopy(product)
  // 处理规格加价参数productSpecsAddPriceDTOList
  let productSpecsAddPriceDTOList = product.productSpecsAddPriceDTOList
  if (productSpecsAddPriceDTOList) {
    productInfo.productSpecsAddPriceDTOList = productSpecsAddPriceDTOList.map(
      (item) => {
        // 设置specsNameCn字段
        item.specsNameCn = getSellerSpecTypeCnWithEn(item.specsName)
        // 设置specsValueEn字段
        item.specsValueCn = getSellerSpecValueCnWithEn(
          item.specsName,
          item.specsValue
        )
        return item
      }
    )
  }
  //  处理surfaceDTOList数组下面的每个元素数组surfaceRelationDTOList中的nameCn
  productInfo.surfaceDTOList?.forEach((surface) => {
    surface.surfaceRelationDTOList?.forEach((relation) => {
      if (relation.name !== 'none') {
        relation.nameCn = getSellerSpecValueCnWithEn(
          'Color',
          relation.name,
          productSpecsList
        )
      }
    })
  })

  // 处理productSpecsDTOList
  const specsType = productInfo?.specsType?.split(',')
  productInfo?.productSpecsDTOList?.forEach((spec, index) => {
    if (spec?.specsValue1) {
      spec.specsValueCn1 = getSellerSpecValueCnWithEn(
        specsType[0],
        spec.specsValue1,
        productSpecsList
      )
    }
    if (spec?.specsValue2) {
      spec.specsValueCn2 = getSellerSpecValueCnWithEn(
        specsType[1],
        spec.specsValue2,
        productSpecsList
      )
    }
  })

  return productInfo
}

/**
 * 校验字段是否包含中文
 * @param {string} field - 要校验的字段
 * @returns {string} - 如果包含中文则返回 '--'，否则返回原字段
 */
export function hasChineseChar(field) {
  if (!field) {
    return '--'
  }
  const chineseRegex = /[\u4e00-\u9fa5]/ // 匹配中文字符
  return chineseRegex.test(field) ? '--' : field
}
