export default async function treeCheckChange(node, data, eventType) {
  if (node.type === 0 && eventType === 1) {
    this.checkedUser =
      data.checkedNodes?.filter((item) => item.type === 1) || []
    // 兼容组织下没有人员
    this.$refs.tree.setCheckedNodes(data.checkedNodes || [])
  }
  let checkedIds = this.checkedUser.map((item) => {
    return item.id
  })
  if (node.type === 1 && typeof data === 'object') {
    if (this.singleSelect) {
      if (checkedIds.includes(node.id)) {
        this.checkedUser = []
        checkedIds = []
      } else {
        this.checkedUser = [node]
        checkedIds = [node.id]
      }
      this.$refs.tree.setCheckedKeys(checkedIds)
    } else {
      if (checkedIds.includes(node.id)) {
        this.checkedUser = this.checkedUser.filter(
          (user) => user.id !== node.id
        )
        checkedIds = checkedIds.filter((id) => id !== node.id)
      } else {
        this.checkedUser.push(node)
        checkedIds.push(node.id)
        // 去重
        this.checkedUser = this.unique(this.checkedUser)
      }
      this.$refs.tree.setCheckedKeys(checkedIds)
    }
  }
  this.checkedId = checkedIds
}
