import request from '@/core/services/request'
import store from '@/store/index.js'
import { replaceStaticSrc } from '@/utils/utils'
//  发送邮件接口(ok)
export function sendMail(data = {}) {
  if (data.content) {
    data.content = replaceStaticSrc(data.content)
  }
  return request({
    url: '/system/email/sendMail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//  存草稿邮件(ok)
export function addDraft(data = {}) {
  return request({
    url: '/system/email/addDraft',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//  更新草稿(ok)
export function updateDraft(data = {}) {
  return request({
    url: '/system/email/updateDraft',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//  上传文件接口
export function uploadFile(data) {
  let params = new FormData()
  for (let key of Object.keys(data)) {
    params.append(key, data[key])
  }
  return request({
    url: '/system/file/updateLoadFile',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

//  上传文件接口
export function uploadPdfFile(params) {
  return request({
    url: '/system/file/updateLoadFile',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
//  邮件左侧综合，数量、文件夹、标签列表
export function maiLeftAside(data = {}) {
  return request({
    url: '/system/email/leftTable',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取已发邮件，草稿，垃圾邮箱，收件箱数量(ok)
export function getAnalysisNum(data = {}) {
  return request({
    url: '/system/email/getBoxCountInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  获取邮件列表(ok)
export function getMailPageList(data = {}) {
  return request({
    url: '/system/email/pageList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 检查用户是否在线
export function checkUserOnline(userId) {
  return request({
    url: '/system/email/dispatch/onlineCheck',
    method: 'post',
    data: [userId],
  })
}

export function getMailDetailById(id) {
  if (!id) {
    return
  }
  return request({
    url: '/system/email/getDetail',
    method: 'post',
    data: { mailId: id }, // businessId: store.state.user.userInfo.userId
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

//  草稿箱删除邮件 (ok)
export function removeMails(ids) {
  return request({
    url: '/system/email/deleteMail',
    method: 'post',
    data: { mailIdList: ids, businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 收件箱下的分类列表接口(ok)
export function catagoryTree(emailAccount) {
  return request({
    url: '/system/mailCatagory/listCatagoryByEmail',
    method: 'post',
    data: {
      emailAccount: emailAccount,
      businessId: store.state.user.userInfo.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 添加分类 (ok)
export function addReceiveCatagory(data = {}) {
  return request({
    url: '/system/mailCatagory/addCatagory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 修改分类(ok)
export function updateReceiveCatagory(data = {}) {
  return request({
    url: '/system/mailCatagory/updateCatagory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 删除分类判断
export function CanDeleteReceiveCatagory(id) {
  return request({
    url: '/system/mailCatagory/canDelete',
    method: 'post',
    data: {
      catagoryIdList: [id],
      businessId: store.state.user.userInfo.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 删除分类(ok)
export function deleteReceiveCatagory(id) {
  return request({
    url: '/system/mailCatagory/deleteCatagory',
    method: 'post',
    data: {
      catagoryIdList: [id],
      businessId: store.state.user.userInfo.userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 变更邮件收件箱分类(ok)
export function changeCatagory(data = {}) {
  return request({
    url: '/system/email/changeCatagory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 变更邮件所属类型(ok)
export function changeBoxType(data = {}) {
  return request({
    url: '/system/email/changeBoxType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
// 移动到文件夹
export function moveMail(data = {}) {
  return request({
    url: '/system/email/moveMail',
    method: 'post',
    data,
  })
}

// 设置邮件已读状态 (ok)
export function setReadFlag(data) {
  return request({
    url: '/system/email/setReadFlag',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 修改邮件标识 (ok)
export function setMailFieldTag(data = {}) {
  return request({
    url: '/system/email/upMail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 查询询盘单是否存在(ok-待验证)
export function getDetailByOrderCode(code) {
  return request({
    url: '/system/mailBusiness/getDetailByOrderCode',
    method: 'post',
    data: { orderCode: code, businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据询盘单号查询报价(ok-待验证)
export function orderPriceSheetEmail(code) {
  return request({
    url: '/system/mailBusiness/orderPriceSheetEmail',
    method: 'post',
    data: { enquiryCode: code, businessId: store.state.user.userInfo.userId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据询盘单号查询报价(未使用)
export function testConnect() {
  return request({
    url: '/system/email/testConnect',
    method: 'post',
    data,
  })
}
// 删除备注
export function deleteMailMemo(data) {
  return request({
    url: '/system/email/deleteMailMemo  ',
    method: 'post',
    data,
  })
}
// 删除询盘单号
export function deleteMailInquiry(data) {
  return request({
    url: '/system/email/deleteMailInquiry ',
    method: 'post',
    data,
  })
}
//  获取标签分页列表
export function mailLabelPageList(data = {}) {
  return request({
    url: '/system/mailLabel/pageList',
    method: 'post',
    data,
  })
}
//  获取标签列表
export function mailLabelList(data = {}) {
  return request({
    url: '/system/mailLabel/listMailLabel',
    method: 'post',
    data,
  })
}
//  保存标签
export function mailLabelSave(data = {}) {
  return request({
    url: '/system/mailLabel/save',
    method: 'post',
    data,
  })
}
//  删除标签
export function mailLabelDelete(data = {}) {
  return request({
    url: '/system/mailLabel/delete',
    method: 'post',
    data,
  })
}
//  清除邮件标签
export function cleanMailLabel(data = {}) {
  return request({
    url: '/system/mailLabel/cleanMailLabel',
    method: 'post',
    data,
  })
}
//  删除邮件标签
export function deleteMailLabelConfig(data = {}) {
  return request({
    url: '/system/email/deleteMailLabelConfig',
    method: 'post',
    data,
  })
}
//  取消延时发送
export function delayCancellation(data = {}) {
  return request({
    url: '/system/email/delayCancellation',
    method: 'post',
    data,
  })
}
// 新建邮件文件夹folder/saveFolder

export function addEmailFolder(data = {}) {
  return request({
    url: '/system/folder/saveFolder',
    method: 'post',
    data,
  })
}

// 获取分派人员账号数据
export function listDispatchUser(emailAccount) {
  return request({
    url: '/system/email/listDispatchUser',
    method: 'post',
    data: {
      emailAccount,
    },
  })
}

// 邮件分派接口
export function dispatch(data = {}) {
  return request({
    url: '/system/email/batchDispatch',
    method: 'post',
    data,
  })
}

export function singleDispatch(data = {}) {
  return request({
    url: '/system/email/dispatch',
    method: 'post',
    data,
  })
}

// 邮件认领
export function singleClaim(data = {}) {
  return request({
    url: '/system/email/claim',
    method: 'post',
    data,
  })
}
export function claim(data = {}) {
  return request({
    url: '/system/email/batchClaim',
    method: 'post',
    data,
  })
}

// 获取主账号抄送邮箱配置
export function getMailAccountConfigVO(data = {}) {
  return request({
    url: '/system/mailSystemConfig/getMailAccountConfigVO',
    method: 'post',
    data,
  })
}

//获取开启分派模式(0:关闭,1:开启)
export function getDispatchEnabled(data = {}) {
  return request({
    url: '/system/mailSystemConfig/getDispatchEnabled',
    method: 'post',
    data,
  })
}

// 移除当前账号下邮件
export function removeMail(data = {}) {
  return request({
    url: '/system/mailUserOperate/deleteMailUserOperate',
    method: 'post',
    data,
  })
}

// 获取认领记录
export function listMailUserOperate(data = {}) {
  return request({
    url: '/system/mailUserOperate/listMailUserOperate',
    method: 'post',
    data,
    loading: false,
  })
}

// 获取邮件原始附件数据

export function getData(data = {}) {
  return request({
    url: '/system/mailOriginAttachment/getData',
    method: 'post',
    data,
    loading: false,
    isResArray: true,
  })
}

//保存附件数据
export function saveUrl(data = {}) {
  return request({
    url: '/system/mailOriginAttachment/saveUrl',
    method: 'post',
    data,
    loading: false,
  })
}

// 下载图片文件流base64
export function downLoadImageEncode(imgPath) {
  return request({
    url: '/system/email/downLoadImageEncode',
    method: 'post',
    data: {
      imgPath,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    // responseType: 'blob',
    loading: false,
    isResArray: true,
  })
}

// 重新发送邮件
export function resendMail(mailId, mailServer) {
  return request({
    url: '/system/email/resend',
    method: 'post',
    data: {
      mailId,
      mailServer,
    },
  })
}

// 获取联系人
export function pageContact({ emailAccount, keyword, pageSize, pageNo }) {
  return request({
    url: '/system/contact/pageContact',
    method: 'post',
    data: {
      emailAccount,
      keyword,
      pageSize,
      pageNo,
    },
  })
}

// 新增签名 url是/system/mailSignature/add

export function addMailSignature(data = {}) {
  return request({
    url: '/system/mailSignature/add',
    method: 'post',
    data,
  })
}

// 新增邮件模板 url是/system/mailTemplate/add

export function addMailTemplate(data = {}) {
  return request({
    url: '/system/mailTemplate/add',
    method: 'post',
    data,
  })
}

// 邮件三联页面配置保存接口 url是/system/userTripleConfig/save

export function saveUserTripleConfig(data = {}) {
  return request({
    url: '/system/userTripleConfig/save',
    method: 'post',
    data,
    loading: false,
  })
}

// 邮件三联页面配置详情 url是/system/userTripleConfig/getDetail

export function getUserTripleConfig(data = {}) {
  return request({
    url: '/system/userTripleConfig/getDetail',
    method: 'get',
    data,
  })
}

// 设置邮件加心状态
export function setHeartFlag(data) {
  return request({
    url: '/system/email/setHeartFlag',
    method: 'POST',
    data,
  })
}

// 邮件标签树
export function mailLabelTreeList(data) {
  return request({
    url: '/system/mailLabel/treeList',
    method: 'POST',
    data,
  })
}

// 修改邮件标签
export function mailLabelUpdate(data) {
  return request({
    url: '/system/mailLabel/update',
    method: 'POST',
    data,
  })
}

// 批量获取邮箱展开的keys
export function getAccountExpandedLabels(emailAccountList) {
  return request({
    url: '/system/mail/unfold/list',
    method: 'POST',
    data: {
      emailAccountList,
    },
    loading: false,
  })
}

export function saveAccountExpandedLabels(data) {
  return request({
    url: '/system/mail/unfold/save',
    method: 'POST',
    data,
    loading: false,
  })
}

// 根据docId查询是否有sage询盘订单信息
export function getOrderEnquiryVOByDocId(data) {
  return request({
    url: '/order/orderEnquiry/getOrderEnquiryVOByDocId',
    method: 'POST',
    data,
  })
}
