const translateConfig = {
  zh: {
    myMail: {
      userOffline: '该人员当前不在线',
      AddAnInquiry: '新增询盘单',
      AddAttachment: '添加附件(20M)',
      AddCustomer: '新增客户',
      AssociateTips: '请先关联询盘单号',
      AssociatedAnInquiry: '关联询盘单',
      Bcc: '密送',
      BrowseComputer: '浏览计算机',
      BulkSingleDisplay: '群发单显',
      Cancel: '取消',
      CancelBcc: '取消密送',
      CancelBulkSingleDisplay: '取消群发单显',
      CancelCc: '取消抄送',
      Cc: '抄送',
      ChooseSendTime: '选择定时发送的时间',
      ClearAllLabels: '清除所有标签',
      ClearTips: '是否确认清除标签？',
      Close: '关闭',
      CompanyAddress: '公司地址',
      CompanyName: '公司名称',
      CondensedInformation: '精简信息',
      Confirm: '确定',
      ContactNumber: '联系电话',
      CustomerLevel: '客户等级',
      CustomerMailbox: '客户邮箱',
      CustomerName: '客户姓名',
      CustomerSource: '客户来源',
      Details: '详细信息',
      EmailsRead: '已读邮件',
      EnterLabelTags: '请输入标签名称',
      EnterTheInquiryNumber: '输入询盘单号',
      FaxNumber: '传真',
      GenerateQuotation: '生成报价单',
      Label: '标签',
      LabelManagement: '标签管理',
      LabelName: '标签名称',
      MarkAs: '标记为',
      MoveTo: '移动至',
      MyRemarks: '我的备注',
      NewLabel: '新建标签',
      NoMailSelected: '未选择邮件',
      NoSignature: '暂无签名',
      NoTemplate: '暂无模板',
      Now: '此刻',
      Operate: '操作',
      PaymentMethod: '付款方式',
      PleaseSelectMail: '请选择邮件',
      ReceiveMail: '收件',
      Recipient: '收件人',
      Remark: '备注',
      RemarksTips: '未填写备注, 是否继续继续填写?',
      Reply: '回复',
      ReplyToAll: '回复所有人',
      ReplayToAllWithFile: '带附件回复所有人',
      ReplyWithAttachments: '带附件回复',
      Repost: '转发',
      SaveDraft: '存草稿',
      SavedDraftsTips: '邮件已保存至草稿箱',
      Search: '搜索',
      SelectSignature: '选择签名',
      Send: '发送',
      SendMail: '发送邮件',
      Sender: '发件人',
      SetUp: '设置',
      SwitchToFullWritingMode: '切换到完整写信模式',
      Time: '时间',
      TimingSend: '定时发送',
      TimingSendTips: '本邮件将在 -- 投递到对方邮箱。',
      Tips: '提示',
      Topic: '主题',
      UnreadMail: '未读邮件',
      SolvedMail: '已处理邮件',
      ViewCustomers: '查看客户',
      WebAddress: '网址',
      WriteAnother: '再写一封',
      WriteMail: '写信',
      assist: '协助',
      invalid: '失效',
      assign: '分配',
      Nooperationpermission: '没有操作权限',
      Forwardtorecipient: '转交收件',
      assignConfirm:
        '是否分配邮件，分配后，邮件将自动从当前账号中删除，数据将自动转到分配后账号中',
      colleagueDirectory: '同事目录',
      otherColleagueDirectory: '其他同事',
      dispatch: '分派',
      claim: '认领',
      pleaseselectyournameafterassignment: '请选择分派人员',
      dissupzhen: '请选择分配后人员名称（支持中英文）',
      claimedsuccessfully: '认领成功',
      dispatchedsuccessfully: '分派成功',
      removedsuccessfully: '移除成功',
      dispatchrecord: '分派明细',
      claimrecord: '认领明细',
      claimant: '认领人',
      receiver: '接收人',
      assignee: '分派人',
      confirmToRemove: '是否确认移除?',
      remove: '移除',
      sendFailed: '发送失败，请重新发送！',
      sendTimeout: '发送超时，请至Gmail后台-已发邮件确认是否发送成功',
      mailBeingDelivered: '邮件投递中...',
      resend: '重新发送',
      noOperationPermission: '投递中和发送失败的邮件不允许操作',
      Pleaseuploadattachments: '请上传附件',
      Pleasedottedline: '请将数据拖拽到虚线中',
      plsD: '请将文件拖到此处，或',
      ctu: '点击上传',
      isLow20: '单个文件不得大于20M',
      total25: '总大小不能超过25M',
      notice: '注意：',
      sameAccount:
        '认领/分派/打标签/标记为仅支持在同一个邮箱号下的数据进行操作，不支持多个账号批量操作！',
      invalidAccounts: '失效账号不允许操作',
      onlyGmail: '只有Gmail账号允许操作',
      notallowed: '已发邮件,草稿, 已删除邮件, 垃圾邮件不允许操作',
      cancel: '取消',
      confirmUpload: '确认上传',
      markBgColor: '请选择标签背景色',
      searchMark: '请输入标签名',
      fullText: '全文',
      date: '日期',
      checkTime: '定时发送时间不能小于当前时间',
      HeartMarkedEmail: '星标邮件',
      Heart: '星标',
      AddHeartMark: '添加星标',
      CancelHeartMark: '取消星标',
      AddHeartMarkSus: '添加星标成功',
      CancelHeartMarkFail: '取消星标成功',
      Parentlabel: '上级标签',
      OneParentMark: '只能选择一个上级标签',
      MarkLevel10: '上级标签可以选到标签最子级，最多支持到10级。',
      DeleteMarkWithChildren: '是否删除当前标签以及当前标签下方的所有子级标签',
      DeleteMark: '是否删除当前标签',
      EditLabel: '编辑标签',
      SelectRenderings: '选择效果图',
      PI: '销售订单号',
      Renderings: '效果图',
      RenderingStatus: '效果图状态',
      PleaseSelectRenderings: '请选择效果图',
      InputOrderCode: '请输入销售单号',
      selectAll: '全选',
      noTitle: '请填写邮件主题',
      noContent: '请填写邮件内容',
      containChinese: '邮件正文中存在中文，是否继续发送邮件?',
      titleContainChinese: '邮件标题中存在中文，是否继续发送邮件？',
      fileChinese: '附件标题中存在中文，是否继续发送此邮件？',
      forgerFile: '你似乎忘记添加附件。是否继续发送此邮件？',
    },
  },
  en: {
    myMail: {
      userOffline: 'This person is not online',
      AddAnInquiry: 'Add An Inquiry',
      AddAttachment: 'Add Attachment (20M)',
      AddCustomer: 'Add Customer',
      AssociateTips: 'Please associate the inquiry number first.',
      AssociatedAnInquiry: 'Associated An Inquiry',
      Bcc: 'Bcc',
      BrowseComputer: 'Browse Computer',
      BulkSingleDisplay: 'Bulk Single Display',
      Cancel: 'Cancel',
      CancelBcc: 'Cancel Bcc',
      CancelBulkSingleDisplay: 'Cancel Bulk Single Display',
      CancelCc: 'Cancel Cc',
      Cc: 'Cc',
      ChooseSendTime: 'Choose a time to send',
      ClearAllLabels: 'Clear All Labels',
      ClearTips: 'Are you sure you want to clear the label?',
      Close: 'Close',
      CompanyAddress: 'Company Address',
      CompanyName: 'Company',
      CondensedInformation: 'Condensed Information',
      Confirm: 'Confirm',
      ContactNumber: 'Contact Number',
      CustomerLevel: 'Customer Level',
      CustomerMailbox: 'Customer Email',
      CustomerName: 'Customer Name',
      CustomerSource: 'Customer Origin',
      Details: 'Details',
      EmailsRead: 'Emails Read',
      EnterLabelTags: 'Please enter a label name.',
      EnterTheInquiryNumber: 'Enter The Inquiry Number',
      FaxNumber: 'FaxNumber',
      GenerateQuotation: 'Generate Quotation',
      Label: 'Label',
      LabelManagement: 'Manage labels',
      LabelName: 'Label Name',
      MarkAs: 'Mark As',
      MoveTo: 'Move To',
      MyRemarks: 'My Remarks',
      NewLabel: 'Create new',
      NoMailSelected: 'No mail selected',
      NoSignature: 'No Signature',
      NoTemplate: 'No Template',
      Now: 'Now',
      Operate: 'Operate',
      PaymentMethod: 'Payment Method',
      PleaseSelectMail: 'Please select mail',
      ReceiveMail: 'Receive Mail',
      Recipient: 'To',
      Remark: 'Remark',
      RemarksTips:
        'The remarks have not been filled in, do you want to continue to fill in?',
      Reply: 'Reply',
      ReplyToAll: 'Reply To All',
      ReplayToAllWithFile: 'Reply To All With Attachments',
      ReplyWithAttachments: 'Reply With Attachments',
      Repost: 'Forward',
      SaveDraft: 'Save Draft',
      SavedDraftsTips: 'Mail has been saved to drafts.',
      Search: 'Search',
      SelectSignature: 'Select Signature',
      Send: 'Send',
      SendMail: 'Send Mail',
      Sender: 'From',
      SetUp: 'Set up',
      SwitchToFullWritingMode: 'Switch To Full Writing Mode',
      Time: 'Sent',
      TimingSend: 'Schedule Send',
      TimingSendTips:
        "This email will be delivered to the recipient's mailbox at -- .",
      Tips: 'Tips',
      Topic: 'Subject',
      UnreadMail: 'Unread Mail',
      SolvedMail: 'Solved Mail',
      ViewCustomers: 'View Customers',
      WebAddress: 'Web Address',
      WriteAnother: 'Write Another',
      WriteMail: 'Compose',
      assist: 'Assist',
      invalid: 'Invalid',
      assign: 'Assign',
      Nooperationpermission: 'No operation permission',
      Forwardtorecipient: 'Forward to recipient',
      assignConfirm:
        'This email will now be removed from your inbox and automatically transferred to the assigned account. Please confirm.',
      colleagueDirectory: 'Colleague Directory',
      otherColleagueDirectory: 'Other Colleagues',
      dispatch: 'Assign',
      claim: 'Claim',
      pleaseselectyournameafterassignment:
        'Please select the person to be assigned',
      dissupzhen:
        'Please select the name of the assigned person (supports Chinese and English)',
      claimedsuccessfully: 'Claim Successful',
      dispatchedsuccessfully: 'Dispatched successful',
      removedsuccessfully: 'Removed successful',
      dispatchrecord: 'Assignment Details',
      claimrecord: 'Claim Details',
      claimant: 'claimant',
      receiver: 'Recipient',
      assignee: 'Assignor',
      confirmToRemove: 'Confirm to remove?',
      remove: 'Remove',
      sendFailed: 'Delivery failed. Please resend!',
      sendTimeout:
        'Sending timed out, please go to the Gmail backend - Sent emails to confirm whether the sending was successful.',
      mailBeingDelivered: 'Email delivery in progress...',
      resend: 'Resend',
      noOperationPermission:
        'Actions are not allowed for emails in transit or failed deliveries.',
      Pleaseuploadattachments: 'Please upload attachments',
      Pleasedottedline: 'Please drag the data into the dotted line',
      plsD: 'Please drag the file here, or',
      ctu: 'Click to upload',
      isLow20: 'A single file must not be larger than 20M',
      total25: 'The total size cannot exceed 25M',
      notice: 'Notice:',
      sameAccount:
        'Claiming/assigning/labeling/marking only supports operations on data under the same email address, and batch operations on multiple accounts are not supported!',
      invalidAccounts: 'Invalid accounts are not allowed to operate',
      onlyGmail: 'Only Gmail accounts are allowed to operate',
      notallowed:
        'Sent emails, drafts, deleted emails, spam emails are not allowed to operate.',
      cancel: 'Cancel',
      confirmUpload: 'Confirm upload',
      markBgColor: 'Please select label background color',
      searchMark: 'Please enter a label name',
      fullText: 'full text',
      date: 'date',
      checkTime:
        'The scheduled sending time cannot be less than the current time',
      HeartMarkedEmail: 'Star marked email',
      Heart: 'Star Mark',
      AddHeartMark: 'Add star mark',
      CancelHeartMark: 'Cancel star mark',
      AddHeartMarkSus: 'Add star mark successful',
      CancelHeartMarkFail: 'Cancel star mark failed',
      Parentlabel: 'Parent label',
      OneParentMark: 'Only one parent label can be selected',
      MarkLevel10:
        'The upper-level label can be selected to the most sub-level of the label, up to 10 levels.',
      DeleteMarkWithChildren:
        'Would you like to delete the current tag and all its sub-level tags?',
      DeleteMark: 'Would you like to delete the current tag',
      EditLabel: 'Edit label',
      SelectRenderings: 'Select rendering',
      PI: 'PI',
      Renderings: 'Renderings',
      RenderingStatus: 'Renderings status',
      PleaseSelectRenderings: 'Please select renderings',
      InputOrderCode: 'Please enter the sales order number',
      selectAll: 'Select all',
      noTitle: 'Please fill in the email subject',
      noContent: 'Please fill in the email content',
      containChinese:
        'There are Chinese characters in the email body. Do you want to continue sending the email?',
      titleContainChinese:
        'There are Chinese characters in the email title. Do you want to continue sending the email?',
      fileChinese:
        'There are Chinese characters in the attachment title. Do you want to continue sending this email?',
      forgerFile:
        'It seems you forgot to add the attachment. Do you want to continue sending this email?',
    },
  },
}

export default translateConfig
