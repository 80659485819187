/* eslint-disable prettier/prettier */
import request from '@/core/services/request'

// 获取平台价格下拉
export function getProductPlatformPriceWay(data) {
  return request({
    url: '/product/productPlatform/getProductPlatformPriceWay',
    method: 'post',
    data,
  })
}
// 审核通过后自动上架到平台勾选
export function selectProductPlatformCode(data) {
  return request({
    url: '/product/productPlatform/selectProductPlatformCode',
    method: 'post',
    data,
  })
}

// 产品类别-sagex下拉选择
export function listPage(data) {
  return request({
    url: '/product/sageCategory/listPage',
    method: 'post',
    data,
  })
}

// 产品科目-theme下拉选择
export function themeListPage(data) {
  return request({
    url: '/product/sageTheme/listPage',
    method: 'post',
    data,
  })
}

// Sage平台协议名称列表查询
export function sageComplianceListPage(data) {
  return request({
    url: '/product/sageCompliance/listPage',
    method: 'post',
    data,
  })
}

// 印刷信息刷新
export function getPlatformProductPrinting(data) {
  return request({
    url: '/product/productPlatform/getPlatformProductPrinting',
    method: 'post',
    data,
  })
}

// 图片信息切换
export function getPlatformProductImgS(data) {
  return request({
    url: '/product/productPlatform/getPlatformProductImgS',
    method: 'post',
    data,
  })
}

// 根据平台价格获取详情
export function getProductPlatformPrice(data) {
  return request({
    url: '/product/productPlatform/getProductPlatformPrice',
    method: 'post',
    data,
  })
}

// 编辑获取保存平台价格下拉
export function getPlatformProductWay(data) {
  return request({
    url: '/product/productPlatform/getPlatformProductWay',
    method: 'post',
    data,
  })
}

// 新增标准品（下一步）
export function nextStepWhenInsert(data) {
  return request({
    url: '/product/product/nextStepWhenInsert',
    method: 'post',
    data,
  })
}

// 编辑标准品（下一步）
export function nextStepWhenModify(data) {
  return request({
    url: '/product/product/nextStepWhenModify',
    method: 'post',
    data,
  })
}

// 系统产品保存第二步，保存提交
export function staging(data) {
  return request({
    url: '/product/platformProduct/staging',
    method: 'post',
    data,
  })
}

// 系统产品保存第二步，确认提交
export function productConfirm(data) {
  return request({
    url: '/product/platformProduct/confirm',
    method: 'post',
    data,
  })
}

// 产品上架到平台
export function upShelf(data) {
  return request({
    url: '/product/product/upShelf',
    method: 'post',
    data,
  })
}

// 系统产品从平台下架
export function downShelf(data) {
  return request({
    url: '/product/product/downShelf',
    method: 'post',
    data,
  })
}

// 获取上架成功平台
export function upShelfPlatformCodes(data) {
  return request({
    url: '/product/product/upShelfPlatformCodes',
    method: 'post',
    data,
  })
}

// 修改Sage产品库存
export function stockUpdate(data) {
  return request({
    url: '/product/platformProduct/sage/stock/update',
    method: 'post',
    data,
  })
} 

// 查询修改Sage产品库存操作记录
export function stockListPage(data) {
  return request({
    url: '/product/platformProduct/sage/log/listPage',
    method: 'post',
    data,
  })
}

// 类别管理-SAGE平台类目下拉
export function sageCategoryListPage(data) {
  return request({
    url: '/product/sageCategory/listPage',
    method: 'post',
    data,
  })
}
