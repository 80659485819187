// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  deleteById as del,
  getCustomerComplainRecords as complainRecords,
  getDetail,
  listPage as getList,
  listHighSeasPage,
  customerClaim,
  customerAssign,
  claimSetting,
  claimSettingEdit,
} from '@/core/services/api/customer-manage/customer-center'

class CusCenterInteractor {
  static getInstance() {
    return this._instance
  }

  async getList(data) {
    const {
      authUserIds,
      company,
      companyName,
      createDate,
      createDateEnd,
      createDateStart,
      creator,
      creatorEn,
      creatorId,
      customerComplainId,
      evaluationCode,
      evaluationDate,
      evaluationDateEnd,
      evaluationDateStart,
      evaluationSource,
      evaluationType,
      level,
      orderBy,
      orderNumber,
      pageLe,
      pageNo,
      reason,
      releatedCustomer,
      releatedCustomerEn,
      releatedCustomerId,
      salesman,
      salesmanEn,
      salesmanId,
      status,
      tenantId,
    } = data
    try {
      return await listPage({
        authUserIds,
        company,
        companyName,
        createDate,
        createDateEnd,
        createDateStart,
        creator,
        creatorEn,
        creatorId,
        customerComplainId,
        evaluationCode,
        evaluationDate,
        evaluationDateEnd,
        evaluationDateStart,
        evaluationSource,
        evaluationType,
        level,
        orderBy,
        orderNumber,
        pageLe,
        pageNo,
        reason,
        releatedCustomer,
        releatedCustomerEn,
        releatedCustomerId,
        salesman,
        salesmanEn,
        salesmanId,
        status,
        tenantId,
      }).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async getCustomerComplainRecords(businessId) {
    try {
      return await complainRecords(businessId).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  async deleteById(businessId) {
    try {
      return await del(businessId).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves the detail of a business.
   *
   * @param {string} businessId - The ID of the business.
   * @return {Promise} A promise that resolves with the detail of the business.
   */
  async getDetail(businessId) {
    try {
      return await getDetail(businessId).catch((err) => {
        throw new Error(err)
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取公海列表数据
   * @param {email} 客户邮箱
   * @param {companyName} 公司名称
   * @param {linkman} 客户名称
   * @param {customerSourceId} 客户来源
   * @param {customerCode} 客户编码
   * @param {customerRank} 客户等级
   * @param {customerType} 客户类型
   * @param {toSeaType} 回归公海原因
   * @param {startSeasTime} 回归公海开始日期
   * @param {endSeasTime} 回归公海结束日期
   * @param {pageNo}
   * @param {pageLe}
   * @param {highSeas}
   */
  listHighSeasPage(params) {
    return listHighSeasPage(params)
  }

  /**
   * 认领
   * @param {auditObjectDTO} 审核模板
   * @param {infoId} 业务id
   * @param {infoLogDTOList}
   */
  customerClaim(params) {
    return customerClaim(params)
  }

  /**
   * 指派
   * @param {assignerCn} 指派人中文
   * @param {assignerEn} 指派人英文
   * @param {assignerId} 指派人id
   * @param {infoId} 业务id
   * @param {auditObjectDTO}
   */
  customerAssign(params) {
    return customerAssign(params)
  }

  /**
   * 认领设置新僧
   * @param {claim} 认领次数
   */
  claimSetting(params) {
    return claimSetting(params)
  }

  /**
   * 认领设置回显
   */
  claimSettingEdit() {
    return claimSettingEdit()
  }
}

CusCenterInteractor._instance = new CusCenterInteractor()

const CustomerCenterInteractor = CusCenterInteractor.getInstance()
export default CustomerCenterInteractor
