const translateConfig = {
  zh: {
    mailConfig: {
      add: '添加',
      isCustomerService: '是否客服',
      yes: '是',
      no: '否',
      open: '开',
      close: '关',
      uName: '姓名',
      authorizedPerson: '被授权人',
      setCustomerService: '设为客服',
      setNonCustomerService: '设为非客服',
      AliyunEnterpriseEmail: '阿里云企业邮箱',
      BindEmail: '绑定邮箱',
      Cancel: '取 消',
      CcMailbox: '抄送邮箱',
      CcMethod: '抄送方式',
      ConfigurationManagement: '配置管理',
      Confirm: '确 定',
      EmailAddress: '邮箱地址',
      EmailOwner: '邮箱归属人',
      EmailSyncCount: '邮件同步数量',
      ExportsRise: '出口抬头',
      Gmail: 'Gmail',
      MailType: '邮件类型',
      Operate: '操作',
      Password: '密码',
      Save: '保存',
      ScheduleProductionEmailDefaultCcSettings:
        '安排生产邮件默认抄送设置： 可设置抄送多个邮箱，设置后，生产邮件自动发送后，会自动抄送给设置的邮箱',
      ScheduleShipmentEmailDefaultCcSettings:
        '安排发货邮件默认抄送设置： 可设置抄送多个邮箱，设置后，发货邮件自动发送后，会自动抄送给设置的邮箱',
      SerialNumber: '序号',
      SyncQuantitySettingTips:
        '邮件同步数量设置：系统绑定邮箱后，会根据设置，拉取邮件',
      SyncRecentEmails: '同步最近邮件',
      TencentEnterpriseMailbox: '腾讯企业邮箱',
      TencentMailbox: '腾讯邮箱',
      UserManagement: '用户管理',
      UpdateEmailPassWord: '更新邮箱密码',
      UpdateEmailPassWordTips:
        '您绑定的邮箱密码发生变更导致无法拉取邮件，请输入最新的邮箱密码进行绑定，修改成功后才能继续使用系统',
      Accountauthorization: '账号授权',
      EmailCenter: '中心站',
      automaticassignment: '自动分配',
      otherconfigurations: '其他配置',
      primaryaccount: '正常登录',
      subaccount: '授权绑定',
      status: '状态',
      forword: '转发',
      reply: '回复',
      statusUpdateStoreadAfterOpenedByOtherAccounts:
        '将其他人打开的会话标记为已读状态',
      statusRemainsUnreadAfterOpenedByOtherAccounts:
        '将其他人打开的会话保持为未读状态',
      authorizedAccountsAreNotAllowedToForwardEM: '被授权的不允许转发邮件',
      authorizedAccountsAreAllowedToForwardEmails:
        '被授权的允许转发我的收件箱邮件',
      authorizedAccountsAreNotAllowedToReplyEmails: '被授权的不允许回复邮件',
      authorizedAccountsAreAllowedToReplyEmails: '被授权的允许回复我的邮件',
      addnew: '新建',
      authorizedemail: '授权邮箱',
      authorizedby: '授权人',
      myemail: '我的邮箱',
      operation: '操作',
      revokeauthorization: '解除授权',
      delete: '删除',
      addemailofsubaccount: '添加绑定人邮箱账号',
      enterusersnameoremailofsubaccount: '请输入转交人员名称或者邮箱账号',
      grantauthorizationto: '绑定人',
      choosemymailaddress: '请选择我的邮箱',
      authorizing: '授权中',
      Theotherpartyhasbeenuntied: '对方已解绑',
      unbundle: '解绑',
      confirmdeauthorization: '是否确认解除授权',
      addSubAccount: '邮箱授权',
      assign: '分配',
      pleaseAddAuthorizedPerson: '请添加被授权人',
      leaveTransfer: '休假转交',
      assignTo: '请选择分配后人员名称或者邮箱账号',
      forwardTo: '请选择转交人员名称或者邮箱账号',
      leaveStartTime: '请选择休假开始时间',
      leaveEndTime: '请选择休假结束时间',
      bindingPerson: '绑定人',
      LeaveCanceled: '休假取消',
      selectTimeTips: '结束时间不能小于开始时间!',
      selectTimeTips1: '开始时间不能大于结束时间!',
      aAssignMailConfirm: '是否分配邮件？',
      enableEmailCenter: '开启中心站',
      enableEmailCenterInfo:
        '提示：中心站开启后所有对外发送的邮箱账号将统一成一个主账号，子账号(业务员账号)系统统一转换格式，收到的邮件也将转换格式。开启后立即生效。关闭后也将立刻失去中心站功能，建议不要轻易关闭，否则可能会给客户带来困扰。',
      systemwide: '全局',
      enablespecifiedaccount: '指定账号开启',
      emailaccount: '邮箱账号',
      owner: '拥有人',
      Notice1:
        '提示：自动拉出系统里面所有邮箱账号，如果有有邮箱没有拉取出来，请前去邮件中心添加，添加后重新打开此页面。',
      select: '选择',
      Notice2: '开启新邮件消息提示(立即生效)',
      Notice3: '关闭(立即生效)',
      time: '时间',
      Mailpullstatus: '邮件拉取状态',
      Pulling: '拉取中',
      Pullcompleted: '拉取完成',
      HasoVacation: '当前选择的客户在休假，是否确认分配！',
      chrome: '点击Google logo进行登录绑定',
      Assigned: '已分配',
      DispatchMode: '开启分派模式。(开启后分派和认领都可以使用)',
      CreateNewLine: '新建一行',
      Function: '功能',
      SendToPrimaryEmail: '发送主邮箱',
      DefaultCCEmail: '默认抄送邮箱',
      Compose: '新建',
      Action: '操作',
      TurnOfftheHeartIcon: '星标关闭',
      TurnOntheHeartIcon: '星标开启',
      dutyConfig: '值班配置',
      resetInfo: '若此人在排班表中，确定后将自动将其从排班表中移除。',
    },
  },
  en: {
    mailConfig: {
      add: 'Add',
      isCustomerService: 'Customer Service',
      yes: 'Yes',
      no: 'No',
      open: 'Open',
      close: 'Close',
      uName: 'Name',
      authorizedPerson: 'Authorized Person',
      setCustomerService: 'Set as Customer Service',
      setNonCustomerService: 'Set as Non-Customer Service',
      AliyunEnterpriseEmail: 'Aliyun Enterprise Email',
      BindEmail: 'Bind Email',
      Cancel: 'Cancel',
      CcMailbox: 'Cc Mailbox',
      CcMethod: 'Cc Method',
      ConfigurationManagement: 'Configuration Management',
      Confirm: 'Confirm',
      EmailAddress: 'Email Address',
      EmailOwner: 'Email Owner',
      EmailSyncCount: 'Email Sync Count',
      ExportsRise: 'RP/RG/SW',
      Gmail: 'Gmail',
      MailType: 'Mail Type',
      Operate: 'Operate',
      Password: 'Password',
      Save: 'Save',
      ScheduleProductionEmailDefaultCcSettings:
        'Arrange the default cc settings for production emails: Multiple mailboxes can be set to cc, after setting, after the production emails are automatically sent, they will be automatically cc to the set mailboxes.',
      ScheduleShipmentEmailDefaultCcSettings:
        'Arrange delivery email default cc setting: Multiple emails can be set to cc, after setting, after delivery email is automatically sent, it will be automatically cc to the set email.',
      SerialNumber: 'S/N',
      SyncQuantitySettingTips:
        'Mail synchronization quantity setting: After the system binds the mailbox, it will pull the mail according to the settings.',
      SyncRecentEmails: 'Sync Recent Emails',
      TencentEnterpriseMailbox: 'Tencent Enterprise Mailbox',
      TencentMailbox: 'Tencent Mailbox',
      UserManagement: 'User Management',
      UpdateEmailPassWord: 'Update email password',
      UpdateEmailPassWordTips:
        'The password of the email you bound has changed, making it impossible to retrieve emails. Please enter the latest email password to bind and modify it successfully before continuing to use the system.',
      Accountauthorization: 'Account Authorization',
      EmailCenter: 'E-mail Center',
      automaticassignment: 'Automatic Assignment',
      otherconfigurations: 'Other Configurations',
      primaryaccount: 'primary account',
      subaccount: 'sub-account',
      status: 'Status',
      forword: 'Forword',
      reply: 'Reply',
      statusUpdateStoreadAfterOpenedByOtherAccounts:
        'Status updates to read after opened by other accounts',
      statusRemainsUnreadAfterOpenedByOtherAccounts:
        'Status remains unread after opened by other accounts',
      authorizedAccountsAreNotAllowedToForwardEM:
        'Authorized accounts are not allowed to forward E-mails',
      authorizedAccountsAreAllowedToForwardEmails:
        'Authorized accounts are allowed to forward E-mails',
      authorizedAccountsAreNotAllowedToReplyEmails:
        'Authorized accounts are not allowed to reply E-mails',
      authorizedAccountsAreAllowedToReplyEmails:
        'Authorized accounts are allowed to reply E-mails',
      addnew: 'Add New',
      authorizedemail: 'Authorized E-mail',
      authorizedby: 'Authorized By',
      myemail: 'My E-mail',
      operation: 'Operation',
      revokeauthorization: 'Revoke Authorization',
      delete: 'Delete',
      addemailofsubaccount: 'Add e-mail of sub-account',
      enterusersnameoremailofsubaccount:
        "Enter user's name or e-mail of sub-account'",
      grantauthorizationto: 'Grant Authorization To',
      choosemymailaddress: 'Choose my e-mail address',
      authorizing: 'Authorizing',
      Theotherpartyhasbeenuntied: 'The other party has been untied',
      unbundle: 'Unbundle',
      confirmdeauthorization: 'Confirm deauthorization',
      addSubAccount: 'Email authorization',
      assign: 'Assign',
      pleaseAddAuthorizedPerson: 'Please add authorized person',
      leaveTransfer: 'Vacation Handover',
      assignTo: 'Assign To',
      forwardTo: 'Forward To',
      leaveStartTime: 'Leave Start Time',
      leaveEndTime: 'Leave End Time',
      bindingPerson: 'Grant Authorization To',
      LeaveCanceled: 'Cancel The Vacation Request',
      selectTimeTips: 'The end time cannot be less than the start time',
      selectTimeTips1: 'The start time cannot be greater than the end time',
      aAssignMailConfirm: 'Whether to assign emails?',
      enableEmailCenter: 'Enable E-mail center',
      enableEmailCenterInfo:
        'Notice: After enabling E-mail center, all outbound emails will be sent from one primary account. Sub-accounts (operator accounts) will also be uniformly formatted in the system, so as incoming e-mails. This change(enable or disable) will take effect immediately upon operating. It is not recommended to casually disable it, as that may inconvenience customers.',
      systemwide: 'System-Wide',
      enablespecifiedaccount: 'Enable Specified Account',
      emailaccount: 'Email Account',
      owner: 'Owner',
      Notice1:
        'The system has automatically retrieved all email accounts. If there are any email accounts that are not displayed here, please go to the email center to add them, then refresh this page.',
      select: 'Select',
      Notice2: 'Enable New Email Notification (Takes Effect Immediately)',
      Notice3: 'Unable New Email Notification (Takes Effect Immediately)',
      time: 'Time',
      Mailpullstatus: 'Mail pull status',
      Pulling: 'Pulling',
      Pullcompleted: 'Pull completed',
      HasoVacation:
        'Selected customer is currently on vacation. Please confirm if you want to proceed',
      chrome: 'Click the Google logo to sign in and link it to your profile',
      Assigned: 'Assigned',
      DispatchMode:
        'Enable Assignment Distribution(both email assignment and claiming will be available)',
      CreateNewLine: 'Add New Row',
      Function: 'Function',
      SendToPrimaryEmail: 'Primary Sender Email',
      DefaultCCEmail: 'Default CC Email',
      Compose: 'Create',
      Action: 'Action',
      TurnOfftheHeartIcon: 'Turn off the star icon',
      TurnOntheHeartIcon: 'Turn on the star icon',
      dutyConfig: 'Duty configuration',
      resetInfo:
        'If this person is in the schedule, he will be automatically removed from the schedule after confirmation.',
    },
  },
}

export default translateConfig
