import {
  getSTSToken,
  getLimit
} from '@/core/services/api/public'

class publicMethods {
  static getInstance() {
    return this._instance
  }

  /**
   * getSTSToken
   */
  getSTSToken() {
    return getSTSToken()
  }


  getLimitApi(type) {
    return getLimit(type)
  }

}

publicMethods._instance = new publicMethods()

export const PublicMethods = publicMethods.getInstance()
