/**
 * 订单设置模块接口
 */
import request from '@/core/services/request'
export * from './hw'
export * from './xly'

export function getCompanyList(data) {
  return request({
    url: '/merchants/company/listPage',
    method: 'post',
    data,
  })
}

export function getCustomerList(data = {}) {
  return request({
    url: '/merchants/info/listTakeCustomerPage',
    method: 'post',
    data,
  })
}

export function getCustomerListAll(data = {}) {
  return request({
    url: '/merchants/info/listPage',
    method: 'post',
    data,
  })
}

//客户来源接口下拉
export function customerSourceList() {
  return request({
    url: '/merchants/source/listPage',
    method: 'post',
    data: {},
  })
}

// 获取分管人是否有询盘记录

export function userHasRecord() {
  return Promise.resolve({
    code: '000000',
    data: 0,
  })
}

// 添加备注(公司和客户通用)

export function addRemark(data = {}) {
  return request({
    url: '/merchants/customerRemark/insertPO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function infoRemark(data = {}) {
  return request({
    url: '/merchants/info/infoRemark',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 校验邮箱是否重复
export function checkEmail(data = {}) {
  return request({
    url: '/merchants/info/checkEmail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 获取公司详情

export function getCompanyDetail(companyId) {
  return request({
    url: '/merchants/company/getDetailForShow',
    method: 'post',
    params: {
      companyId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 公司加入黑名单
export function toBlackCompany(data) {
  return request({
    url: '/merchants/company/toBlack',
    method: 'post',
    data,
  })
}

// 公司移除黑名单

export function outBlackCompany(companyId) {
  return request({
    url: '/merchants/company/outBlack',
    method: 'post',
    params: {
      companyId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 新增客户根据邮箱查询公司
export function selectConpanyByEmail(data = {}) {
  return request({
    url: '/merchants/linkman/selectPageCompanyByEmail',
    method: 'post',
    data,
  })
}

// 新增客户

export function insertCustomer(data) {
  return request({
    url: '/merchants/info/insertPO',
    method: 'post',
    data,
  })
}

//查询公司基本信息、支付方式、收货地址
export function getCompanyVO(companyId) {
  return request({
    url: `/merchants/info/getCompanyVO`,
    method: 'post',
    params: {
      companyId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 获取备注

export function remarkListData(data) {
  return request({
    url: `/merchants/customerRemark/listData`,
    method: 'post',
    data,
  })
}

// 新增备注 客户和公司

export function insertPORemark(data) {
  return request({
    url: `/merchants/customerRemark/insertPO`,
    method: 'post',
    data,
  })
}

// 分页列表查询公海客户

export function selectAllCustomerByInfoIds(infoIds) {
  return request({
    url: `/merchants/info/selectAllCustomerByInfoIds`,
    method: 'post',
    params: {
      infoIds,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function selectAllCustomerByManagerIds(managerIds) {
  return request({
    url: `/merchants/info/selectAllCustomerByManagerIds`,
    method: 'post',
    params: {
      managerIds,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 新增收货地址
export function insertReceivingAddress(data) {
  return request({
    url: `/merchants/receivingAddressConfig/insertShipAddress`,
    method: 'post',
    data,
  })
}

// 编辑收货地址
export function updateShipAddress(data) {
  return request({
    url: `/merchants/receivingAddressConfig/updateShipAddress`,
    method: 'post',
    data,
  })
}

// 客户等级成长记录-积分总值
export function customerScoreRecordCount(data) {
  return request({
    url: `/merchants/customerScoreRecord/customerScoreRecordCount`,
    method: 'post',
    data,
  })
}