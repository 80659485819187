import {
  getOrderSettlementStatusReason,
  manualSettlement,
  getOrderSimpleInvoice,
  checkOrderInvoice,
  orderPrintOrder,
  enquiryImport,
  enquiryDownload,
  getShippingPurchesList,
  purchaseListPage,
  supplierListForSelect,
  purchaseLogicDeleteByIds,
  listPaymentMethod,
  updateRenderingStatusUsingPOST,
  getTrackingNos,
  orderCreatePayOrder,
  getInvoice,
  orderInvoiceRegistration,
  orderListInvoiceDetail,
  handleExceptionPre,
  warehousingExceptionHandling,
  getTrackingDetail,
  updateTrackingPO,
  purchaseGetDetailForShow,
  purchaseOrderExtendDetail,
  purchaseOrderExtendInsertPO,
  invoiceDelete,
  addSealedContract,
  updateQC,
  storageFindWarehouseList2,
  inspectionInsertPO,
  applicationWarehouseInInsertPO,
  orderDisposalUnqualified,
  orderApplicationPayOrderInsertPO,
  inspectionGetDetailForEdit,
  listCountry,
  findProvince,
  queryProductList,
  exportDeliverLabelWord,
} from '@/core/services/api/order'

class orderListInteractor {
  static getInstance() {
    return this._instance
  }

  async getStatusReason(data) {
    return await getOrderSettlementStatusReason(data)
  }
  // 手动结算
  async setManualSettlement(data) {
    try {
      const res = await manualSettlement(data).catch((err) => {
        throw new Error(err)
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 生成简易发票
  async getSimpleInvoice(data) {
    try {
      const res = await getOrderSimpleInvoice(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 检查是否生成发票
  async getCheckInvoice(data) {
    try {
      const res = await checkOrderInvoice(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 打印发票
  async setOrderPrintOrder(data) {
    try {
      const res = await orderPrintOrder(data).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  // 询盘导入
  async enquiryImport(data) {
    return await enquiryImport(data)
  }
  // 询盘下载模板
  async enquiryDownload() {
    return await enquiryDownload()
  }
  // 通过子产品查父产品  或  通过父产品查找子产品
  async getShippingPurchesList(data) {
    const { orderCode, orderProductUniqueId, parentSku, sku } = data
    try {
      const res = await getShippingPurchesList({
        orderCode,
        orderProductUniqueId,
        parentSku,
        sku,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * Retrieves a purchase list page based on the provided data.
   *
   * @param {Object} data - An object containing various parameters to filter the purchase list.
   * @param {number} data.amountDrawbackTaxEx - The amount of drawback tax excluding tax.
   * @param {number} data.amountExcludingTaxEx - The amount excluding tax.
   * @param {number} data.amountIncludingTaxEx - The amount including tax.
   * @param {number} data.amountInvoicedEx - The amount invoiced excluding tax.
   * @param {number} data.amountPaidEx - The amount paid excluding tax.
   * @param {number} data.amountPayableEx - The amount payable excluding tax.
   * @param {number} data.amountTotalEx - The total amount excluding tax.
   * @param {number} data.businessId - The ID of the business.
   * @param {number} data.buyerId - The ID of the buyer.
   * @param {string} data.buyerNameEn - The name of the buyer in English.
   * @param {string} data.creatorEn - The name of the creator in English.
   * @param {number} data.creatorId - The ID of the creator.
   * @param {number} data.discountAmount - The discount amount.
   * @param {number} data.discountAmountEx - The discount amount excluding tax.
   * @param {string} data.discountReason - The reason for the discount.
   * @param {string} data.endAuditTime - The end audit time.
   * @param {string} data.endCompleteDate - The end complete date.
   * @param {string} data.endCreateTime - The end create time.
   * @param {number} data.endProductNum - The end product number.
   * @param {number} data.endTotalPrice - The end total price.
   * @param {number} data.endUnInvoicePrice - The end un-invoice price.
   * @param {number} data.endUnpaidPrice - The end unpaid price.
   * @param {boolean} data.invoiced - A flag indicating if the purchase has been invoiced.
   * @param {boolean} data.invoicedEx - A flag indicating if the purchase has been invoiced excluding tax.
   * @param {boolean} data.isAgreement - A flag indicating if the purchase is an agreement.
   * @param {Array<number>} data.keyIds - An array of key IDs.
   * @param {string} data.merchandiserNameEn - The name of the merchandiser in English.
   * @param {string} data.number - The purchase number.
   * @param {boolean} data.only - A flag indicating if only the purchase list is required.
   * @param {string} data.orderBy - The order by field.
   * @param {number} data.pageLe - The page length.
   * @param {number} data.pageNo - The page number.
   * @param {string} data.productInfo - The product information.
   * @param {string} data.purchaser - The purchaser.
   * @param {string} data.salesOrderNumber - The sales order number.
   * @param {string} data.startAuditTime - The start audit time.
   * @param {string} data.startCompleteDate - The start complete date.
   * @param {string} data.startCreateTime - The start create time.
   * @param {number} data.startProductNum - The start product number.
   * @param {number} data.startTotalPrice - The start total price.
   * @param {number} data.startUnInvoicePrice - The start un-invoice price.
   * @param {number} data.startUnpaidPrice - The
   * @param {string} data.states - The states.
   * @param {number} data.supplierId - The ID of the supplier.
   *  @param {string} data.supplierInfo - The supplier information.
   * @param {string} data.supplierName - The supplier name.
   * @param {string} data.type - The type.
   * @return {Promise}
   * @throws {Error} If there is an error
   * */
  async purchaseListPage(data) {
    const {
      amountDrawbackTaxEx,
      amountExcludingTaxEx,
      amountIncludingTaxEx,
      amountInvoicedEx,
      amountPaidEx,
      amountPayableEx,
      amountTotalEx,
      businessId,
      buyerId,
      buyerNameEn,
      creatorEn,
      creatorId,
      discountAmount,
      discountAmountEx,
      discountReason,
      endAuditTime,
      endCompleteDate,
      endCreateTime,
      endProductNum,
      endTotalPrice,
      endUnInvoicePrice,
      endUnpaidPrice,
      invoiced,
      invoicedEx,
      isAgreement,
      keyIds,
      merchandiserNameEn,
      number,
      only,
      orderBy,
      pageLe,
      pageNo,
      productInfo,
      purchaser,
      salesOrderNumber,
      startAuditTime,
      startCompleteDate,
      startCreateTime,
      startProductNum,
      startTotalPrice,
      startUnInvoicePrice,
      startUnpaidPrice,
      states,
      supplierId,
      supplierInfo,
      startWarehouseInDate,
      endWarehouseInDate,
      supplierName,
      type,
      sortName,
      sortType,
    } = data

    try {
      const res = await purchaseListPage({
        amountDrawbackTaxEx,
        amountExcludingTaxEx,
        amountIncludingTaxEx,
        amountInvoicedEx,
        amountPaidEx,
        amountPayableEx,
        amountTotalEx,
        businessId,
        buyerId,
        buyerNameEn,
        creatorEn,
        creatorId,
        discountAmount,
        discountAmountEx,
        discountReason,
        endAuditTime,
        endCompleteDate,
        endCreateTime,
        endProductNum,
        endTotalPrice,
        endUnInvoicePrice,
        endUnpaidPrice,
        invoiced,
        invoicedEx,
        isAgreement,
        keyIds,
        merchandiserNameEn,
        number,
        only,
        orderBy,
        pageLe,
        pageNo,
        productInfo,
        purchaser,
        salesOrderNumber,
        startAuditTime,
        startCompleteDate,
        startCreateTime,
        startProductNum,
        startTotalPrice,
        startUnInvoicePrice,
        startUnpaidPrice,
        states,
        supplierId,
        supplierInfo,
        supplierName,
        startWarehouseInDate,
        endWarehouseInDate,
        type,
        sortName,
        sortType,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves a list of suppliers for selection.
   *
   * @return {Promise} A promise that resolves with the supplier list.
   * @throws {Error} If there is an error retrieving the supplier list.
   */
  async supplierListForSelect() {
    try {
      const res = await supplierListForSelect().catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Deletes multiple purchases based on their IDs.
   *
   * @param {Object} options - The options object.
   * @param {Array} options.ids - An array of purchase IDs to delete.
   * @param {string} options.delReason - The reason for deleting the purchases.
   * @return {Promise<Object>} - A promise that resolves to the response object.
   */
  async purchaseLogicDeleteByIds({ ids, delReason }) {
    try {
      const res = await purchaseLogicDeleteByIds({
        ids,
        delReason,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Asynchronously lists the payment methods for a given business ID.
   *
   * @param {Object} businessId - The ID of the business.
   * @return {Promise<Object>} - A promise that resolves to the response object containing the payment methods.
   */
  async listPaymentMethod({ businessId }) {
    try {
      const res = await listPaymentMethod({
        businessId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Updates the rendering status using a POST request.
   *
   * @param {Object} purchaseOrderId - The ID of the purchase order.
   * @return {Promise} The response from the POST request.
   */
  async updateRenderingStatusUsingPOST({ purchaseOrderId }) {
    try {
      const res = await updateRenderingStatusUsingPOST({
        purchaseOrderId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves the tracking numbers for a given purchase order ID.
   *
   * @param {number} purchaseOrderId - The ID of the purchase order.
   * @return {Promise} A promise that resolves to the tracking numbers
   *                   associated with the purchase order.
   */
  async getTrackingNos(purchaseOrderId) {
    try {
      const res = await getTrackingNos(purchaseOrderId).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Creates and pays for an order.
   *
   * @param {object} params - The parameters for creating the order.
   * @param {string[]} params.businessIds - The IDs of the businesses involved in the order.
   * @return {Promise} A promise that resolves to the created and paid order.
   */
  async orderCreatePayOrder({ businessIds }) {
    try {
      const res = await orderCreatePayOrder({ businessIds }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 发票识别-图片ocr识别
  getInvoiceApi(url) {
    return getInvoice(url)
  }

  /**
   * @description generate invoice
   * @param {*} accountName
   * @param {*} dutyAccount
   * @param {*} invoiceNumber
   * @param {*} invoiceType
   * @param {*} invoiceDate
   * @param {*} fileAnnex
   * @returns
   */
  async orderInvoiceRegistration(params) {
    try {
      const res = await orderInvoiceRegistration(params).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves the detailed invoice information for a list of orders.
   *
   * @param {Object} options - The options object.
   * @param {Array} options.businessIds - An array of business IDs.
   * @return {Promise} A promise that resolves with the detailed invoice information.
   */
  async orderListInvoiceDetail({ businessIds }) {
    try {
      const res = await orderListInvoiceDetail({
        businessIds,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Asynchronously handles the exception pre-processing.
   *
   * @param {object} businessId - The ID of the business.
   * @return {Promise} A promise that resolves with the result of the exception pre-processing.
   */
  async handleExceptionPre({ businessId }) {
    try {
      const res = await handleExceptionPre({
        businessId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Asynchronously handles the exception pre-processing for warehousing.
   *
   * @param {Array.<{
   *   amountDrawbackTaxEx: number,
   *   amountExcludingTaxEx: number,
   *   amountIncludingTaxEx: number,
   *   amountTime: string,
   *   associationId: string,
   *   businessNameEn: string,
   *   declareCn: string,
   *   delayRefundAmountEx: number,
   *   discountAmount: number,
   *   discountAmountEx: number,
   *   discountPrice: number,
   *   discountPriceEx: number,
   *   expenseEx: number,
   *   expenses: number,
   *   financeMethod: string,
   *   financeMethodCode: string,
   *   financeUrl: string,
   *   fromBusinessId: string,
   *   fromBusinessName: string,
   *   fromBusinessNameEn: string,
   *   image: string,
   *   inspectionRefundAmountEx: number,
   *   mainFlag: number,
   *   numCombination: number,
   *   orderProductUniqueId: string,
   *   otherCostEx: number,
   *   parentId: string,
   *   parentName: string,
   *   parentSku: string,
   *   parentSpecsValue: string,
   *   parentUrl: string,
   *   priceExcludingTaxEx: number,
   *   priceIncludingTaxEx: number,
   *   processedNum: number,
   *   purchaseOrderId: string,
   *   purchaseProductId: string,
   *   refundUnitPrice: number,
   *   setUpChargeEx: number,
   *   sonProductType: number,
   *   splitFlag: number,
   *   statusRemark: string,
   *   supplierDiscount: number,
   *   supplierDiscountRemaks: string,
   *   type: number,
   *   variance: number
   * }>} params - The array of objects representing the parameters for exception handling.
   * @return {Promise} A promise that resolves with the result of the exception pre-processing.
   */
  async warehousingExceptionHandling(params) {
    try {
      const res = await warehousingExceptionHandling(params).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves the tracking detail for a given order tracking ID.
   *
   * @param {type} orderTrackingId - The ID of the order tracking.
   * @return {type} res - The tracking detail for the order tracking ID.
   * @throws {Error} error - If there is an error retrieving the tracking detail.
   */
  async getTrackingDetail(orderTrackingId) {
    try {
      const res = await getTrackingDetail(orderTrackingId).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Updates the tracking PO.
   *
   * @param {number} amountTotal - The total amount.
   * @param {string} area - The area.
   * @param {string} content - The content.
   * @param {string} creator - The creator.
   * @param {string} creatorEn - The creator in English.
   * @param {string} creatorId - The creator ID.
   * @param {Object} deleteNodeDTO - The delete node DTO.
   * @param {number} deliveryDays - The delivery days.
   * @param {string} enclosure - The enclosure.
   * @param {Object} extendedFields - The extended fields.
   * @param {string} manualUpdateType - The manual update type.
   * @param {string} modeType - The mode type.
   * @param {Object} nodeDTO - The node DTO.
   * @param {string} nodeType - The node type.
   * @param {string} oldShipNo - The old ship number.
   * @param {string} orderProductUniqueId - The unique ID of the order product.
   * @param {string} orderTrackingId - The order tracking ID.
   * @param {string} parentSku - The parent SKU.
   * @param {Object} productDTO - The product DTO.
   * @param {string} purchaseCreateDate - The purchase create date.
   * @param {string} purchaseOrderId - The purchase order ID.
   * @param {string} purchaseOrderNumber - The purchase order number.
   * @param {string} realDate - The real date.
   * @param {string} salesOrderNumber - The sales order number.
   * @param {boolean} sample - The sample.
   * @param {string} shipNo - The ship number.
   * @param {string} sku - The SKU.
   * @return {Promise} The updated tracking PO.
   */

  async updateTrackingPO({
    amountTotal,
    area,
    content,
    creator,
    creatorEn,
    creatorId,
    deleteNodeDTO,
    deliveryDays,
    enclosure,
    extendedFields,
    manualUpdateType,
    modeType,
    nodeDTO,
    nodeType,
    oldShipNo,
    orderProductUniqueId,
    orderTrackingId,
    parentSku,
    productDTO,
    purchaseCreateDate,
    purchaseOrderId,
    purchaseOrderNumber,
    realDate,
    salesOrderNumber,
    sample,
    shipNo,
    sku,
  }) {
    try {
      const res = await updateTrackingPO({
        amountTotal,
        area,
        content,
        creator,
        creatorEn,
        creatorId,
        deleteNodeDTO,
        deliveryDays,
        enclosure,
        extendedFields,
        manualUpdateType,
        modeType,
        nodeDTO,
        nodeType,
        oldShipNo,
        orderProductUniqueId,
        orderTrackingId,
        parentSku,
        productDTO,
        purchaseCreateDate,
        purchaseOrderId,
        purchaseOrderNumber,
        realDate,
        salesOrderNumber,
        sample,
        shipNo,
        sku,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Retrieves the purchase detail for display.
   *
   * @param {Object} options - The options for retrieving the purchase detail.
   * @param {string} options.businessId - The ID of the business.
   * @return {Promise<Object>} The purchase detail.
   */
  async purchaseGetDetailForShow({ businessId }) {
    try {
      const res = await purchaseGetDetailForShow({
        businessId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  // 获取发货凭证详情
  purchaseOrderExtendDetailApi(businessId) {
    return purchaseOrderExtendDetail(businessId)
  }

  // 新增发货凭证
  purchaseOrderExtendInsertPOApi(data) {
    const { businessId, shipmentDate, orderFiles } = data
    // orderFiles数组对象：name, url, size, time
    return purchaseOrderExtendInsertPO({ businessId, shipmentDate, orderFiles })
  }

  /**
   * Deletes an invoice.
   *
   * @param {object} purchaseInvoiceId - The ID of the invoice to be deleted.
   * @return {Promise} A promise that resolves to the response from the server.
   */
  async invoiceDelete({ purchaseInvoiceId }) {
    try {
      const res = await invoiceDelete({
        purchaseInvoiceId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Adds a sealed contract to the database.
   *
   * @param {object} options - The options for adding a sealed contract.
   * @param {string} options.businessId - The ID of the business.
   * @param {object} options.sealedContract - The sealed contract object.
   * @return {Promise} A promise that resolves with the result of adding the sealed contract.
   */
  async addSealedContract({ businessId, sealedContract }) {
    try {
      const res = await addSealedContract({
        businessId,
        sealedContract,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Updates the QC (Quality Control) information for a merchandiser.
   *
   * @param {object} param - The parameters for updating the QC.
   * @param {string} param.merchandiserId - The ID of the merchandiser.
   * @param {string} param.merchandiserName - The name of the merchandiser.
   * @param {string} param.purchaseOrderId - The ID of the purchase order.
   * @return {Promise<object>} The updated QC information.
   */
  async updateQC({ merchandiserId, merchandiserName, purchaseOrderId }) {
    try {
      const res = await updateQC({
        merchandiserId,
        merchandiserName,
        purchaseOrderId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Asynchronously finds a list of warehouses in the storage.
   *
   * @param {boolean} hasAuth - Indicates if the user has authorization.
   * @param {boolean} hasCloudWarehouse - Indicates if the warehouse is in the cloud.
   * @return {Promise<any>} The result of the storage find warehouse list operation.
   */
  async storageFindWarehouseList2({ hasAuth, hasCloudWarehouse }) {
    try {
      const res = await storageFindWarehouseList2({
        hasAuth,
        hasCloudWarehouse,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Inserts a new PO into the inspection system.
   *
   * @param {object} params - The parameters for the PO.
   * @param {string} params.businessId - The ID of the business.
   * @param {string} params.businessName - The name of the business.
   * @param {string} params.buyerId - The ID of the buyer.
   * @param {string} params.buyerName - The name of the buyer.
   * @param {string} params.category - The category of the PO.
   * @param {string} params.categoryId - The ID of the category.
   * @param {string} params.createType - The type of creation.
   * @param {Date} params.deliveryDate - The delivery date.
   * @param {Date} params.expectDeliveryDate - The expected delivery date.
   * @param {string} params.fileAnnex - The file annex.
   * @param {string} params.inspectionAddress - The inspection address.
   * @param {string} params.inspectionAddressType - The type of inspection address.
   * @param {Date} params.inspectionDate - The date of inspection.
   * @param {string} params.inspectionOrderId - The ID of the inspection order.
   * @param {Array} params.inspectionProductEditDTOList - The list of inspection product edit DTO.
   * @param {string} params.inspectionSource - The source of the inspection.
   * @param {string} params.inspectionStage - The stage of the inspection.
   * @param {string} params.inspectionType - The type of inspection.
   * @param {string} params.inspector - The inspector.
   * @param {string} params.inspectorId - The ID of the inspector.
   * @param {string} params.logisticsCompanyNo - The logistics company number.
   * @param {boolean} params.noInspectionRequired - Whether inspection is required or not.
   * @param {string} params.number - The number of the PO.
   * @param {string} params.processOrderCode - The process order code.
   * @param {string} params.processOrderFlag - The process order flag.
   * @param {string} params.purchaseOrderId - The ID of the purchase order.
   * @param {string} params.purchaseOrderNumber - The number of the purchase order.
   * @param {string} params.reSubInspectionOrderId - The ID of the resubmitted inspection order.
   * @param {string} params.remark - The remark for the PO.
   * @param {string} params.state - The state of the PO.
   * @param {string} params.supplierId - The ID of the supplier.
   * @param {string} params.supplierName - The name of the supplier.
   * @param {string} params.tenantId - The ID of the tenant.
   * @return {Promise} Promise object representing the result of the insertion.
   * @throws {Error} If an error occurs during the insertion.
   */
  async inspectionInsertPO({
    businessId,
    businessName,
    buyerId,
    buyerName,
    category,
    categoryId,
    createType,
    deliveryDate,
    expectDeliveryDate,
    fileAnnex,
    inspectionAddress,
    inspectionAddressType,
    inspectionDate,
    inspectionOrderId,
    inspectionProductEditDTOList,
    inspectionSource,
    inspectionStage,
    inspectionType,
    inspector,
    inspectorId,
    logisticsCompanyNo,
    noInspectionRequired,
    number,
    processOrderCode,
    processOrderFlag,
    purchaseOrderId,
    purchaseOrderNumber,
    reSubInspectionOrderId,
    remark,
    state,
    supplierId,
    supplierName,
    tenantId,
  }) {
    try {
      const res = await inspectionInsertPO({
        businessId,
        businessName,
        buyerId,
        buyerName,
        category,
        categoryId,
        createType,
        deliveryDate,
        expectDeliveryDate,
        fileAnnex,
        inspectionAddress,
        inspectionAddressType,
        inspectionDate,
        inspectionOrderId,
        inspectionProductEditDTOList,
        inspectionSource,
        inspectionStage,
        inspectionType,
        inspector,
        inspectorId,
        logisticsCompanyNo,
        noInspectionRequired,
        number,
        processOrderCode,
        processOrderFlag,
        purchaseOrderId,
        purchaseOrderNumber,
        reSubInspectionOrderId,
        remark,
        state,
        supplierId,
        supplierName,
        tenantId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Inserts a purchase order into the application warehouse.
   *
   * @param {Object} params - The parameters for inserting the purchase order.
   * @param {number} params.applicationWarehouseInId - The ID of the application warehouse.
   * @param {Array} params.applicationWarehouseInProductEditDTOList - The list of product edits for the warehouse.
   * @param {Object} params.auditObjectDTO - The audit object for the purchase order.
   * @param {boolean} params.autoLock - Indicates whether the purchase order should be locked automatically.
   * @param {number} params.buyerId - The ID of the buyer.
   * @param {string} params.buyerName - The name of the buyer.
   * @param {string} params.currency - The currency of the purchase order.
   * @param {number} params.exchangeRate - The exchange rate for the purchase order.
   * @param {Array} params.fileUrls - The URLs of any files associated with the purchase order.
   * @param {string} params.orderNumber - The order number of the purchase order.
   * @param {number} params.purchaseOrderId - The ID of the purchase order.
   * @param {number} params.purchasingDemandProductId - The ID of the purchasing demand product.
   * @param {string} params.remark - The remark for the purchase order.
   * @param {number} params.supplierId - The ID of the supplier.
   * @param {string} params.supplierName - The name of the supplier.
   * @param {number} params.warehouseId - The ID of the warehouse.
   * @param {string} params.warehouseInDate - The date of the warehouse in.
   * @param {number} params.warehouseInId - The ID of the warehouse in.
   * @param {string} params.warehouseInNumber - The number of the warehouse in.
   * @param {string} params.warehouseName - The name of the warehouse.
   * @returns {Promise} - A promise that resolves with the result of inserting the purchase order.
   */
  async applicationWarehouseInInsertPO({
    applicationWarehouseInId,
    applicationWarehouseInProductEditDTOList,
    auditObjectDTO,
    autoLock,
    buyerId,
    buyerName,
    currency,
    exchangeRate,
    fileUrls,
    orderNumber,
    purchaseOrderId,
    purchasingDemandProductId,
    remark,
    supplierId,
    supplierName,
    warehouseId,
    warehouseInDate,
    warehouseInId,
    warehouseInNumber,
    warehouseName,
  }) {
    try {
      const res = await applicationWarehouseInInsertPO({
        applicationWarehouseInId,
        applicationWarehouseInProductEditDTOList,
        auditObjectDTO,
        autoLock,
        buyerId,
        buyerName,
        currency,
        exchangeRate,
        fileUrls,
        orderNumber,
        purchaseOrderId,
        purchasingDemandProductId,
        remark,
        supplierId,
        supplierName,
        warehouseId,
        warehouseInDate,
        warehouseInId,
        warehouseInNumber,
        warehouseName,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * A description of the entire function.
   *
   * @param {Object} options - The options object containing the following properties:
   *   - {type} actualInspectionStandard - Description of actualInspectionStandard parameter
   *   - {type} actualResultDescription - Description of actualResultDescription parameter
   *   - {type} boxGaugeUrl - Description of boxGaugeUrl parameter
   *   - {type} fileAnnex - Description of fileAnnex parameter
   *   - {type} generalQuantity - Description of generalQuantity parameter
   *   - {type} generalQuantityRate - Description of generalQuantityRate parameter
   *   - {type} inspectionAddress - Description of inspectionAddress parameter
   *   - {type} inspectionAddressType - Description of inspectionAddressType parameter
   *   - {type} inspectionDate - Description of inspectionDate parameter
   *   - {type} inspectionFileAnnex - Description of inspectionFileAnnex parameter
   *   - {type} inspectionHandleStatus - Description of inspectionHandleStatus parameter
   *   - {type} inspectionId - Description of inspectionId parameter
   *   - {type} inspectionQualifiedNum - Description of inspectionQualifiedNum parameter
   *   - {type} inspectionRefundAmount - Description of inspectionRefundAmount parameter
   *   - {type} inspectionRefundRemark - Description of inspectionRefundRemark parameter
   *   - {type} inspectionRequirements - Description of inspectionRequirements parameter
   *   - {type} inspector - Description of inspector parameter
   *   - {type} inspectorId - Description of inspectorId parameter
   *   - {type} logisticsCompanyNo - Description of logisticsCompanyNo parameter
   *   - {type} numInspection - Description of numInspection parameter
   *   - {type} orderProductUniqueId - Description of orderProductUniqueId parameter
   *   - {type} overallQuantityRate - Description of overallQuantityRate parameter
   *   - {type} packingInformation - Description of packingInformation parameter
   *   - {type} productPackagingUrl - Description of productPackagingUrl parameter
   *   - {type} productUrl - Description of productUrl parameter
   *   - {type} purchaseProductId - Description of purchaseProductId parameter
   *   - {type} remark - Description of remark parameter
   *   - {type} samplingQuantity - Description of samplingQuantity parameter
   *   - {type} seriousQuantity - Description of seriousQuantity parameter
   *   - {type} seriousQuantityRate - Description of seriousQuantityRate parameter
   *   - {type} uploadCounts - Description of uploadCounts parameter
   * @return {Promise<type>} - Description of the return value
   */
  async orderDisposalUnqualified({
    actualInspectionStandard,
    actualResultDescription,
    boxGaugeUrl,
    fileAnnex,
    generalQuantity,
    generalQuantityRate,
    inspectionAddress,
    inspectionAddressType,
    inspectionDate,
    inspectionFileAnnex,
    inspectionHandleStatus,
    inspectionId,
    inspectionQualifiedNum,
    inspectionRefundAmount,
    inspectionRefundRemark,
    inspectionRequirements,
    inspector,
    inspectorId,
    logisticsCompanyNo,
    numInspection,
    orderProductUniqueId,
    overallQuantityRate,
    packingInformation,
    productPackagingUrl,
    productUrl,
    purchaseProductId,
    remark,
    samplingQuantity,
    seriousQuantity,
    seriousQuantityRate,
    uploadCounts,
  }) {
    try {
      const res = await orderDisposalUnqualified({
        actualInspectionStandard,
        actualResultDescription,
        boxGaugeUrl,
        fileAnnex,
        generalQuantity,
        generalQuantityRate,
        inspectionAddress,
        inspectionAddressType,
        inspectionDate,
        inspectionFileAnnex,
        inspectionHandleStatus,
        inspectionId,
        inspectionQualifiedNum,
        inspectionRefundAmount,
        inspectionRefundRemark,
        inspectionRequirements,
        inspector,
        inspectorId,
        logisticsCompanyNo,
        numInspection,
        orderProductUniqueId,
        overallQuantityRate,
        packingInformation,
        productPackagingUrl,
        productUrl,
        purchaseProductId,
        remark,
        samplingQuantity,
        seriousQuantity,
        seriousQuantityRate,
        uploadCounts,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Insert a pay order into the order application.
   *
   * @param {string} accountName - The name of the account.
   * @param {number} amountApplication - The application amount.
   * @param {number} amountApplicationEx - The application amount (ex).
   * @param {object[]} applicationPayOrderChildEditDTOList - The list of child edit DTOs.
   * @param {string} area - The area.
   * @param {object} auditObjectDTO - The audit object DTO.
   * @param {string} bankAccount - The bank account.
   * @param {string} bankName - The bank name.
   * @param {string} buyerId - The ID of the buyer.
   * @param {string} buyerName - The name of the buyer.
   * @param {string} currency - The currency.
   * @param {string} dutyAccount - The duty account.
   * @param {string} entryType - The entry type.
   * @param {number} exchangeRate - The exchange rate.
   * @param {string} fileAnnex - The file annex.
   * @param {string} orderNumber - The order number.
   * @param {string} payType - The pay type.
   * @param {string} purchaseOrderId - The ID of the purchase order.
   * @param {string} purchaser - The purchaser.
   * @param {string} refundOrderId - The ID of the refund order.
   * @param {string} refundReason - The reason for the refund.
   * @param {string} remark - The remark.
   * @param {string} state - The state.
   * @param {string} supplierBankAccountId - The ID of the supplier bank account.
   * @param {string} supplierId - The ID of the supplier.
   * @param {string} supplierName - The name of the supplier.
   * @param {string} type - The type.
   * @return {Promise<Object>} The inserted pay order.
   */
  async orderApplicationPayOrderInsertPO({
    accountName,
    amountApplication,
    amountApplicationEx,
    applicationPayOrderChildEditDTOList,
    area,
    auditObjectDTO,
    bankAccount,
    bankName,
    buyerId,
    buyerName,
    currency,
    dutyAccount,
    entryType,
    exchangeRate,
    fileAnnex,
    orderNumber,
    payType,
    purchaseOrderId,
    purchaser,
    refundOrderId,
    refundReason,
    remark,
    state,
    supplierBankAccountId,
    supplierId,
    supplierName,
    type,
  }) {
    try {
      const res = await orderApplicationPayOrderInsertPO({
        accountName,
        amountApplication,
        amountApplicationEx,
        applicationPayOrderChildEditDTOList,
        area,
        auditObjectDTO,
        bankAccount,
        bankName,
        buyerId,
        buyerName,
        currency,
        dutyAccount,
        entryType,
        exchangeRate,
        fileAnnex,
        orderNumber,
        payType,
        purchaseOrderId,
        purchaser,
        refundOrderId,
        refundReason,
        remark,
        state,
        supplierBankAccountId,
        supplierId,
        supplierName,
        type,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  async inspectionGetDetailForEdit({ businessId, skus, orderProductUniqueId }) {
    try {
      const res = await inspectionGetDetailForEdit({
        businessId,
        skus,
        orderProductUniqueId,
      }).catch((err) => {
        throw new Error(err)
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  async listCountryApi(data) {
    const res = await listCountry(data)
    return res
  }

  async findProvinceApi(data) {
    const res = await findProvince(data)
    return res
  }

  async queryProductListApi(orderCodeList) {
    const res = await queryProductList(orderCodeList)
    return res
  }

  async exportDeliverLabelWordApi(data) {
    const res = await exportDeliverLabelWord(data)
    return res
  }
}

orderListInteractor._instance = new orderListInteractor()

export const OrderListInteractor = orderListInteractor.getInstance()
