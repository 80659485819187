const translateConfig = {
  zh: {
    cusManage: {
      Add: '新增',
      AddCustomer: '新增客户',
      AddFollowUpRecord: '新增跟进记录',
      AddToBlacklist: '加入黑名单',
      AdvancedFilter: '高级筛选',
      All: '全部',
      AllCustomers: '全部客户',
      AreYouSure: '是否确认将该客户扔回公海？',
      AssociatedOrderType: '关联订单类型',
      Cancel: '取消',
      CompanyAddress: '公司地址',
      CompanyName: '公司名称',
      Confirm: '确认',
      ContactDetails: '联系方式',
      ContactNumber: '联系电话',
      Contacts: '联系人',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomDisplay: '自定义显示',
      CustomerCode: '客户编码',
      CustomerLevel: '客户等级',
      CustomerMailbox: '客户邮箱',
      CustomerSource: '客户来源',
      CustomerType: '客户类型',
      Edit: '编辑',
      FaxNumber: '传真号',
      FollowUp: '跟进',
      FollowUpDirection: '跟进方向',
      FollowUpPurpose: '跟进目的',
      FollowUpTime: '跟进时间',
      FollowUpTopic: '跟进主题',
      ImResponsible: '我负责的',
      InCharge: '分管',
      OfficialCustomer: '正式客户',
      Operate: '操作',
      OrderNumber: '订单编号',
      OrderVolume: '订单总成交量',
      PaymentMethod: '付款方式',
      PotentialCustomers: '潜在客户',
      Reason: '原因',
      Remark: '备注',
      Reset: '重置',
      ResponsiblePerson: '负责人',
      ReturnToTheHighSeasRemainingTime: '回归公海剩余时间',
      Save: '保存',
      Search: '搜索',
      ThrownBackIntoTheOpenSea: '转入公海',
      Tips: '提示',
      TotalOrderAmount: '订单总成交额',
      Transfer: '转让',
      WebAddress: '网址',
      Evaluationtype: '评价类型',
      Detail: '详情',
      Skype: 'Skype',
      WhatsApp: 'WhatsApp',
      WeChat: 'WeChat',
      Tag: '标签',
      Balance: '余额($)',
      SystemRemark: '系统备注',
      BusinessRemarks: '业务备注',
      MKTRemark: 'MKT备注',
      Status: '状态',

      CustomerEvaluationNo: '客评编号',
      ReasonForEvaluation: '评价原因',
      EvaluationSource: '评价来源',
      EvaluationLevel: '评价等级',
      EvaluationDate: '评价日期',
      AssociatedOrderNo: '关联订单号',
      CustomerRepresentative: '客户代表',
      AssociatedCustomers: '关联客户',
      ClientCompany: '客户公司',

      InterestedCustomers: '有意向客户',
      CustomersToBeFollowedUp: '待跟进客户',
      UnsignedCustomer: '下单未签收客户',
      TransactionCustomers: '成交客户',
      KeyCustomers: '重点客户',
      Finished: '已完成',
      Modifying: '修改审核中',
      TransferredToBlacklistReview: '转入黑名单审核中',
      ApprovalRejection: '审核驳回',
      Blacklist: '黑名单',

      OtherFeedback: '其他反馈',
      Bad: '差评',
      Ongoing: '进行中',
      ClosingApplicationUnderReview: '完结申请审核中',
      Done: '已完成',
      Reject: '驳回',

      CustomerName: '客户名称',
      Time: '时间',
      Name: '姓名',
      Remarks: '备注说明',

      systemRemarkTips1: '当前客户历史一共有',
      systemRemarkTips2: '单退款',
      systemRemarkTips3: '请仔细沟通和加大服务力度',
      mailbox: '邮箱',
      PersonInCharge: '分管人',
      CountdownToEnteringTheHighSeas: '进入公海倒计时',
      DocumentDetails: '单据明细',
      tooltip: '系统自动标记的，不支持修改，有退款则自动统计',
      BlacklistedCustomers: '黑名单客户',
      rank: '级',
    },
  },
  en: {
    cusManage: {
      Add: 'Add',
      AddCustomer: 'Add Customer',
      AddFollowUpRecord: 'Add Follow Up Record',
      AddToBlacklist: 'Add to Blacklist',
      AdvancedFilter: 'Advanced Search',
      All: 'All',
      AllCustomers: 'All Customers',
      AreYouSure: "Are you sure you're convert to a public customer?",
      AssociatedOrderType: 'Associated Order Type',
      Cancel: 'Cancel',
      CompanyAddress: 'Company Address',
      CompanyName: 'Company Name',
      Confirm: 'Confirm',
      ContactDetails: 'Contact Details',
      ContactNumber: 'Contact Number',
      Contacts: 'Contacts',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CustomDisplay: 'Customize Display',
      CustomerCode: 'Customer Code',
      CustomerLevel: 'Customer Level',
      CustomerMailbox: 'Customer Email',
      CustomerSource: 'Customer Source',
      CustomerType: 'Customer Type',
      Edit: 'Edit',
      FaxNumber: 'Fax Number',
      FollowUp: 'Follow Up',
      FollowUpDirection: 'Follow Up Direction',
      FollowUpPurpose: 'Follow Up Purpose',
      FollowUpTime: 'Follow Up Time',
      FollowUpTopic: 'Follow Up Topic',
      ImResponsible: 'My Customers',
      InCharge: 'In Charge',
      OfficialCustomer: 'Official Customer',
      Operate: 'Operate',
      OrderNumber: 'Order Number',
      OrderVolume: 'Order Quantity',
      PaymentMethod: 'Payment Method',
      PotentialCustomers: 'Potential Customers',
      Reason: 'Reason',
      Remark: 'Remark',
      Reset: 'Reset',
      ResponsiblePerson: 'Responsible Person',
      ReturnToTheHighSeasRemainingTime:
        'Countdown to becoming a public customer',
      Save: 'Save',
      Search: 'Search',
      ThrownBackIntoTheOpenSea: 'Convert to a public customer',
      Tips: 'Tips',
      TotalOrderAmount: 'Total Order Amount',
      Transfer: 'Transfer',
      WebAddress: 'Web Address',
      Evaluationtype: 'Evaluation type',
      Detail: 'Detail',
      Skype: 'Skype',
      WhatsApp: 'WhatsApp',
      WeChat: 'WeChat',
      Tag: 'Tag',
      Balance: 'Balance($)',
      SystemRemark: 'System Remark',
      BusinessRemarks: 'Business Remark',
      MKTRemark: 'MKT Remark',
      Status: 'Status',
      CustomerEvaluationNo: 'Customer Evaluation No',
      ReasonForEvaluation: 'Reason For Evaluation',
      EvaluationSource: 'Evaluation Source',
      EvaluationLevel: 'Evaluation Level',
      EvaluationDate: 'Feedback Date',
      AssociatedOrderNo: 'Associated Order No',
      CustomerRepresentative: 'Customer Representative',
      AssociatedCustomers: 'Associated Customers',
      ClientCompany: 'Client Company',
      InterestedCustomers: 'Interested Customers',
      CustomersToBeFollowedUp: 'Customers To Be Followed Up',
      UnsignedCustomer: 'Unsigned Customer',
      TransactionCustomers: 'Transaction Customers',
      KeyCustomers: 'VIP clients',
      Finished: 'Finished',
      Modifying: 'Modifying',
      TransferredToBlacklistReview: 'Transferred To Blacklist Review',
      ApprovalRejection: 'Approval Rejection',
      OtherFeedback: 'Other Feedback',
      Bad: 'Bad',
      Ongoing: 'Ongoing',
      ClosingApplicationUnderReview: 'Closing Application Under Review',
      Done: 'Done',
      Reject: 'Reject',
      CustomerName: 'CustomerName',
      Time: 'Time',
      Name: 'Name',
      Remarks: 'Remarks',
      systemRemarkTips1: 'There are',
      systemRemarkTips2: 'refunds in the current customer history',
      Blacklist: 'Blacklist',
      mailbox: 'Mailbox',
      PersonInCharge: 'Person In Charge',
      CountdownToEnteringTheHighSeas: 'Countdown To Entering The High Seas',
      DocumentDetails: 'Document Details',
      tooltip:
        'It is automatically marked by the system, and does not support modification. If there is a refund, it will be automatically counted',
      systemRemarkTips3:
        'Please communicate carefully and increase the service',
      BlacklistedCustomers: 'Blacklisted Customers',
      rank: 'Rank',
    },
  },
}

export default translateConfig
