import request from '@/core/services/request'

//  产品设置-所有数据
export function listProductSetting(data) {
  return request({
    url: '/product/productSetting/listProductSetting',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//  产品设置-新增
export function insertPO(data) {
  return request({
    url: '/product/productSetting/insertPO',
    method: 'post',
    data,
  })
}

//  产品设置-更新
export function updatePO(data) {
  return request({
    url: '/product/productSetting/updatePO',
    method: 'post',
    data,
  })
}

//  产品设置-删除
export function deleteById(data) {
  return request({
    url: '/product/productSetting/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//  产品设置-根据id查找
export function getDetail(data) {
  return request({
    url: '/product/productSetting/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//  产品设置-根据type查找
export function getDetailByType(data) {
  return request({
    url: '/product/productSetting/getDetailByType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//  产品设置-根据Ids查询数据
export function listProductSettingByIds(data) {
  return request({
    url: '/product/productSetting/listProductSettingByIds',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//查询产品规格类型列表
export function listSpecsType(data) {
  return request({
    url: '/product/productSpecsSetting/listSpecsType',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增/修改产品规格设置
export function productSpecsSettingSave(data) {
  return request({
    url: '/product/productSpecsSetting/save',
    method: 'post',
    data,
  })
}

// 查询产品规格值列表
export function listSpecsValue(data) {
  return request({
    url: '/product/productSpecsSetting/listSpecsValue',
    method: 'post',
    data,
  })
}

// 删除产品规格设置
export function removeProductSpecsSetting(id) {
  return request({
    url: `/product/productSpecsSetting/remove/${id}`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
