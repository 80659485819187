import {
  sendMail,
  listDispatchUser,
  dispatch,
  singleDispatch,
  singleClaim,
  claim,
  getMailAccountConfigVO,
  getDispatchEnabled,
  removeMail,
  listMailUserOperate,
  getData,
  saveUrl,
  downLoadImageEncode,
  resendMail,
  pageContact,
  addMailSignature,
  addMailTemplate,
  saveUserTripleConfig,
  getUserTripleConfig,
  maiLeftAside,
  setHeartFlag,
  mailLabelTreeList,
  mailLabelUpdate,
  getAccountExpandedLabels,
  saveAccountExpandedLabels,
  getOrderEnquiryVOByDocId,
  checkUserOnline,
} from '@/core/services/api/mail/mail.js'

import {
  getEmailName,
  isInEmailName,
} from '@/views/mail/my-mail/utils/mail-data'

import { MailSettingInteractor } from '@/core'

import { BusEmit } from '@/utils/eventBus'

import store from '@/store'

class mailInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   *发送邮件
   */
  async sendMailApi() {
    return await sendMail()
  }

  /**
   * 分派人员下拉
   */
  async listDispatchUserApi(emailAccount) {
    return await listDispatchUser(emailAccount)
  }

  /**
   * 邮件分派
   */
  async dispatchApi({ mailIds, userId }) {
    return await dispatch({
      mailIds,
      userId,
    })
  }
  async singleDispatchApi({ mailIds, userId }) {
    return await singleDispatch({
      mailId: mailIds[0],
      userId,
    })
  }
  /**
   * 邮件认领
   */
  async singleClaimApi({ mailIds }) {
    return await singleClaim({
      mailId: mailIds[0],
    })
  }
  /**
   * 邮件认领
   */
  async claimApi({ mailIds }) {
    return await claim({
      mailIds,
    })
  }

  /**
   * 获取主账号抄送邮箱配置
   */
  async getMailAccountConfigVOApi({ emailAccount, tenantId }) {
    return await getMailAccountConfigVO({
      emailAccount,
      tenantId,
    })
  }

  /**
   * 获取开启分派模式(0:关闭,1:开启)
   */
  async getDispatchEnabledApi() {
    return await getDispatchEnabled()
  }

  /**
   * 移除邮件
   */
  async removeMailApi({ mailId, userId }) {
    return await removeMail({
      mailId,
      userId,
    })
  }

  /**
   * 获取分派和认领记录
   * @param {type} 类型(0:认领,1:分派)
   */
  async listMailUserOperateApi({ mailId, type }) {
    return await listMailUserOperate({
      mailId,
      type,
    })
  }

  /**
   * 获取邮件原始附件数据
   */
  async getDataApi(id) {
    if (!id) {
      return
    }
    return await getData({
      id,
      mailServer: 'Gmail',
    })
  }

  /**
   * 保存附件URL
   */
  async saveUrlApi(attachmentUrl, id) {
    return await saveUrl({
      attachmentUrl,
      id,
    })
  }

  /**
   * 下载图片
   */
  async downLoadImageEncodeApi(attachmentUrl) {
    return await downLoadImageEncode(attachmentUrl)
  }

  /**
   * 重新发送邮件
   */
  resendMailApi(mailId, mailServer) {
    return resendMail(mailId, mailServer)
  }

  /**
   * 获取联系人
   */
  pageContactApi(params) {
    return pageContact(params)
  }

  /**
   * 新增签名
   */
  addMailSignatureApi(data = {}) {
    return addMailSignature({
      businessId: data.businessId,
      content: data.content,
      emailAccountList: data.emailAccount,
      tenantId: data.tenantId,
      title: data.title,
    })
  }

  /**
   * 新增模板
   */
  addMailTemplateApi(data = {}) {
    return addMailTemplate({
      businessId: data.businessId,
      templateContent: data.templateContent,
      emailAccountList: data.emailAccount,
      tenantId: data.tenantId,
      templateName: data.templateName,
    })
  }

  /**
   * 邮件三联页面配置保存
   */
  saveUserTripleConfigApi(data = {}) {
    return saveUserTripleConfig({
      centerWidth: data.centerWidth,
      leftWidth: data.leftWidth,
    })
  }

  /***
   * 邮件三联页面配置详情
   */
  getUserTripleConfigApi() {
    return getUserTripleConfig()
  }

  async maiLeftAsideApi(businessId, ctx) {
    try {
      let emailAccounts = []
      let res = await maiLeftAside({ businessId })
      if (res?.code === '000000') {
        _initMail(res.data, ctx)
        ctx.leftData = await addFolder(res.data || [], ctx)
        ctx.$store.commit('mail/setReceiveCatgoryList', ctx.leftData)
        ctx.$store.commit(
          'mail/setLeftTableData',
          JSON.parse(JSON.stringify(ctx.leftData))
        )
        ctx.$store.commit('mail/setReceiveCatgoryList', ctx.leftData)

        // 数据处理
        ctx.leftData?.forEach((item) => {
          emailAccounts.push(item.emailAccount)
          ctx.activeNames.push(item.emailAccount) // 设置左边栏全部展开
          ctx.$set(item, 'maskList', item.mailLabelVOS)
          ctx.$store.commit('mail/setReceiveCatgoryList', {
            [item.emailAccount]: {
              records: item.mailLabelVOS,
            },
          })
          if (item.folderConfigVOS?.length) {
            item.folderConfigVOS.forEach((ele, index) => {
              ele.mailServer = item.mailServer

              if (isInEmailName(ele.name)) {
                let obj = getEmailName(ele.name)
                ele.CnName = obj.name
                ele.icon = obj.icon
                ele.unOper = obj.unOper
                ele.langKey = obj.langKey
              } else {
                // 自定义文件夹 后端传的是时间戳。不能用
                if (
                  ele.children &&
                  ele.children.length > 0 &&
                  ele.mailServer === '阿里云企业邮箱'
                ) {
                  ele.children = [
                    ...ctx.setChildParentId(
                      ele.children,
                      (ele.folderConfigId = 100 + index)
                    ),
                  ]
                }
              }

              ctx.totalUnread += Number(ele.mailunReadTotalCount)
            })
          }
          if (item.mailServer === 'Gmail') {
            item.folderConfigVOS?.forEach((tar) => {
              if (tar.fullName === '[Gmail]') {
                tar.children = tar.children?.filter((ele) => {
                  if (isInEmailName(ele.name)) {
                    let obj = getEmailName(ele.name)
                    ele.CnName = obj.name
                    ele.icon = obj.icon
                    ele.unOper = obj.unOper
                    ele.langKey = obj.langKey
                  }

                  return (
                    ele.fullName !== '[Gmail]/重要' &&
                    ele.fullName !== '[Gmail]/已加星标'
                    // && ele.fullName !== '[Gmail]/所有邮件'
                  )
                })
              }
            })
          }
        })
        // 获取展开的标签
        store.dispatch('mail/getExpandMailLabels', emailAccounts)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * @param {*} heartFlag  加心标记(0:未加心,1:加心)
   * @param {*} mailIdList 邮件ID集合
   * @returns
   */
  async setHeartFlagApi(heartFlag, mailIdList) {
    return setHeartFlag({
      heartFlag,
      mailIdList,
    })
  }

  /**
   * 获取邮箱标签树
   * @param {*}
   * @returns
   */
  async mailLabelTreeListApi({
    emailAccount, // 邮箱账号
  }) {
    return mailLabelTreeList({ emailAccount })
  }

  /**
   * 更新邮件标签
   * @param {*}
   * @returns
   */
  async mailLabelUpdateApi({
    businessId, // 业务id
    emailAccount, // 邮箱账号
    labelColor, // 标签颜色
    labelName, // 标签名称
    mailLabelId, // 标签id
    parentId, // 父标签id
  }) {
    return mailLabelUpdate({
      businessId,
      emailAccount,
      labelColor,
      labelName,
      mailLabelId,
      parentId,
    })
  }

  // 批量获取邮箱展开的keys
  async getAccountExpandedLabelsApi(emailAccountList, unfoldType = 0) {
    let res = await getAccountExpandedLabels(emailAccountList)
    return res
  }

  // 保存邮箱展开标签
  async saveAccountExpandedLabelsApi(data) {
    let { emailAccount, nodeIds, type = 0 } = data
    saveAccountExpandedLabels({
      emailAccount,
      nodeIds,
      type,
    })
  }

  // 根据docId查询是否有sage询盘订单信息
  getOrderEnquiryVOByDocIdApi(docId) {
    return getOrderEnquiryVOByDocId({ docId })
  }

  /**
   * 检查用户是否在线
   * @param {string} userId 用户ID
   * @returns {Promise} 返回Promise对象，res.data为true表示用户在线，false表示用户不在线
   */
  async checkUserOnlineApi(userId) {
    return await checkUserOnline(userId)
  }
}

function _initMail(data, ctx) {
  if (data?.length == 0) {
    ctx.$store.commit('mail/setCurrentAccount', '')
    BusEmit('MailList', 'updatePage')
    return
  }
  if (!ctx.$route.query.fromHomePage && !ctx.$store.state.mail.currentAccount) {
    ctx.$store.commit(
      'mail/setCurrentAccount',
      data && data.length ? data[0].emailAccount : ''
    )
  }
}

async function addFolder(leftData, ctx) {
  leftData = JSON.parse(JSON.stringify(leftData))
  let heartEnabled = 0
  let authConfig = await MailSettingInteractor.myAuthConfigApi()
  if (authConfig?.code === '000000') {
    heartEnabled = !!authConfig.data?.heartEnabled
  }
  BusEmit('ActionBar', 'setHeartEnabled', heartEnabled)

  BusEmit('MailList', 'updateheartEnabled', heartEnabled)

  let userId = ctx.userInfo.userId
  // 添加心标目录

  leftData = leftData.map((item) => {
    if (heartEnabled) {
      let heartFolder = {
        folderConfigId: `${item.emailAccount}_heart`,
        name: `${item.emailAccount}_heart`,
        fullName: `erp_heart_folder`,
        children: null,
        emailAccount: item.emailAccount,
        isHeartFolder: true,
        icon: 'iconfont icon-star',
        mailServer: item.mailServer,
        langKey: 'myMail.HeartMarkedEmail',
        unOper: true,
      }
      item.folderConfigVOS.unshift(heartFolder)
    }

    // 处理爱心文件夹
    if (
      Array.isArray(item?.folderConfigVOS) &&
      Array.isArray(item?.mailAccountUserVOS) &&
      item.mailAccountUserVOS.length > 0
    ) {
      // 取出当前用户
      let cUser = item.mailAccountUserVOS.find((user) => user.userId === userId)
      if (cUser) {
        cUser.folderConfigId = cUser.id // 生成家的文件夹id
        cUser.name = cUser.userNameEn || ''
        cUser.fullName = cUser.userNameEn || ''
        cUser.children = null
        cUser.emailAccount = item.emailAccount
        // 打标记
        cUser.isUserFolder = true
        cUser.icon = 'iconfont icon-weibiaoti-_huabanfuben'
        cUser.mailServer = 'Gmail'
        cUser.unOper = true
      }

      let otherUsers = item?.mailAccountUserVOS
        ?.filter((user) => user.userId !== userId)
        .map((s) => {
          s.folderConfigId = s.id
          s.name = s.userNameEn || ''
          s.emailAccount = item.emailAccount
          s.isUserFolder = true
          s.fullName = s.userNameEn || ''
          s.icon = 'iconfont icon-a-zu1632'
          s.mailServer = 'Gmail'
          s.unOper = true
          return s
        })

      // 区分普通同事和其他同事
      const normalUsers = otherUsers
        .filter((user) => user.userType !== 3)
        .map((user) => {
          if (user.userType === 1) {
            user.color = '#02A7F0'
            user.icon = 'iconfont icon-userGroup2'
          }
          return user
        })
      const otherColleagues = otherUsers.filter((user) => user.userType === 3)

      // 设置同事目录
      let partnerFolder = {
        name: 'ColleagueDirectory',
        fullName: 'ColleagueDirectory',
        children: normalUsers,
        emailAccount: item.emailAccount,
        isUserFolder: true,
        icon: 'iconfont icon-tongshiqunzu',
        userId: 'ALL_USER_ID',
        langKey: 'myMail.colleagueDirectory',
        mailServer: 'Gmail',
        unOper: true,
      }

      // 设置其他同事目录
      let otherPartnerFolder = {
        name: 'OtherColleagueDirectory',
        fullName: 'OtherColleagueDirectory',
        children: otherColleagues,
        emailAccount: item.emailAccount,
        isUserFolder: true,
        icon: 'iconfont icon-bot2',
        userId: 'OTHER_USER_ID',
        langKey: 'myMail.otherColleagueDirectory',
        mailServer: 'Gmail',
        unOper: true,
      }

      if (otherUsers.length > 0) {
        let folderConfigId = `${otherUsers[0].id}${otherUsers[0].emailAccount}`
        // 将其他同事目录添加到普通同事目录的children中
        if (normalUsers.length > 0) {
          if (otherColleagues.length > 0) {
            partnerFolder.children.push({
              ...otherPartnerFolder,
              folderConfigId: `other_${folderConfigId}`,
            })
          }
          item.folderConfigVOS.unshift({
            ...partnerFolder,
            folderConfigId,
          })
          ctx.accountFolderIds.push(folderConfigId)
        } else if (otherColleagues.length > 0) {
          // 如果只有其他同事，直接添加其他同事目录
          let otherFolderConfigId = `other_${folderConfigId}`
          item.folderConfigVOS.unshift({
            ...otherPartnerFolder,
            folderConfigId: otherFolderConfigId,
          })
          ctx.accountFolderIds.push(otherFolderConfigId)
        }
      }
      if (cUser) {
        item.folderConfigVOS.unshift(cUser)
      }
    }
    return item
  })
  return leftData
}

mailInteractor._instance = new mailInteractor()

export const MailInteractor = mailInteractor.getInstance()
