import {
  listSpecsType,
  productSpecsSettingSave,
  listSpecsValue,
  removeProductSpecsSetting,
} from '@/core/services/api/product/productSetting'

class ProductSettingInteractor {
  static getInstance() {
    return this._instance
  }

  // 查询产品规格类型列表
  listSpecsTypeApi() {
    return listSpecsType()
  }

  // 新增/修改产品规格设置
  productSpecsSettingSaveApi(data) {
    return productSpecsSettingSave({
      disable: data.disable,
      id: data.id,
      nameCn: data.nameCn,
      nameEn: data.nameEn,
      parentId: data.parentId,
      productType: 0, // 0-标准品 1-定制品
      valueType: data.valueType, // 0-规格类型 1-规格值
    })
  }
  listSpecsValueApi(data) {
    return listSpecsValue({
      nameCn: data.nameCn,
      nameEn: data.nameEn,
      pageLe: data.pageLe,
      pageNo: data.pageNo,
      parentId: data.parentId,
    })
  }

  removeProductSpecsSettingApi(id) {
    return removeProductSpecsSetting(id)
  }
}

ProductSettingInteractor._instance = new ProductSettingInteractor()

export const productSettingInteractor = ProductSettingInteractor.getInstance()
