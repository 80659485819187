<template>
  <div class="erp-app-main">
    <section>
      <transition mode="out-in" name="fade-transform">
        <erp-keep-alive v-if="routerView" />
      </transition>
    </section>
    <!-- <erp-footer /> -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ErpProgress from 'nprogress'
  import { activePathMap } from '@/mixins/backConfirm'

  export default {
    name: 'ErpAppMain',
    data() {
      return {
        routerView: true,
      }
    },
    computed: {
      ...mapGetters({
        theme: 'settings/theme',
        extra: 'settings/extra',
        visitedRoutes: 'tabs/visitedRoutes',
      }),
      changeData() {
        return [
          ...new Set(
            this.visitedRoutes
              .filter(
                (item) =>
                  !item.meta.noKeepAlive &&
                  item.name !== this.extra.transferRouteName
              )
              .flatMap((item) => item.matched)
          ),
        ]
      },
    },
    watch: {
      changeData: {
        handler(visitedRoutes) {
          //this.setCachedRoutes(visitedRoutes)
        },
      },
    },
    created() {
      const flag = this.stopReloadRouterView()
      if (flag) return console.log('阻止通过事件派发刷新页面', flag)
      const { showProgressBar } = this.theme
      // 单页面情况下重载路由
      this.$Bus.$on('reload-router-view', () => {
        this.routerView = false
        if (showProgressBar) ErpProgress.start()
        this.$nextTick(() => {
          this.routerView = true
          setTimeout(() => {
            if (showProgressBar) ErpProgress.done()
          }, 200)
        })
      })
    },
    methods: {
      // 校验是否要阻止reload-router-view事件执行
      stopReloadRouterView() {
        const list = Object.keys(activePathMap)
        const { path } = this.$route
        return list.includes(path)
      }
      // ...mapActions({
      //   setCachedRoutes: 'routes/setCachedRoutes',
      // }),
    },
  }
</script>
