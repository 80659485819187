const translateConfig = {
  zh: {
    productCenter: {
      Add: '新增',
      AdvancedFilter: '高级筛选',
      AsLowAs: '低至',
      AuditProgress: '审核进度',
      Blacklist: '黑名单',
      Cancel: '取消',
      Cc: '抄送',
      Check: '查看',
      ChineseName: '中文名称',
      Clone: '克隆',
      Close: '关闭',
      Code: '编码',
      Confirm: '确认',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomDisplay: '自定义显示',
      CustomizedProduct: '订制品',
      Delete: '删除',
      Deleted: '已删除',
      Edit: '编辑',
      EnglishName: '英文名称',
      InReview: '审核中',
      InitiateApplication: '发起申请',
      Inputting: '录入中',
      InquiryGeneration: '询盘生成',
      Inventory: '库存',
      ListDraggableToSort: '列表可拖动排序',
      MajorSuppliers: '主要供应商',
      ModifiedTime: '修改时间',
      NoAuditProgress: '还没有审核进度',
      NoNeedToAudit: '无需审核',
      NumberOfEnquiries: '询盘数',
      NumberOfOrders: '下单数',
      NumberOfStockInWarehouse: '库存在仓数',
      Operate: '操作',
      Operation: '操作',
      PassedTheReview: '审核通过',
      PendingReview: '待审核',
      ReviewTransfer: '审核转交',
      ProductCategory: '产品类别',
      ProductCode: '产品编码',
      ProductImage: '产品图片',
      ProductOperations: '产品运营',
      ProductSource: '产品来源',
      ProductType: '产品类型',
      ProductionLine: '产线',
      ProductionSystem: '生产系统',
      ProductsSelected: '已选 -- 个产品',
      ReasonForRevocation: '撤销原因',
      RemainingStock: '库存可用量',
      Reset: '重置',
      ReviewDismissed: '审核驳回',
      Revoke: '撤销',
      RemovedFromShelves: '下架',
      Revoked: '已撤销',
      SKUList: 'SKU列表',
      Save: '保存',
      Search: '搜索',
      SelfAdd: '自行新增',
      SpecificationDrawing: '规格图',
      StandardProduct: '标准品',
      Status: '状态',
      MallStatus: '商城状态',
      Submit: '提交',
      Supplier: '供应商',
      SureDelete: '是否确认删除？',
      Tips: '提示',
      TransferOwnership: '转让归属权',
      Transferor: '转让人',
      CopySucceeded: '复制成功',
      SuccessfulTransferOfOwnership: '归属权转让成功',
      AvailableStock: '可用库存',
      line100: '一行一项，最多一百行',
      Sage: 'SAGE',
      ESP: 'ESP',
      DC: 'DC',
      website: '官网',
      selPlatForm: '选择平台',
      platstatus: '平台状态',
      WhetherTheCustoms: '报关信息是否完善',
      AllProducts: '全部产品',
      CustomsDeclarationNot: '报关信息未完善（审核状态已通过）',
      CustomsDeclarationHas: '报关信息已完善',
      ApprovalTime: '审核通过时间',
      YouTubeVideo: 'YouTube视频',
      all: '全部',
      added: '已添加',
      notAdded: '未添加',
      ViewVideo: '查看视频',
      listingPlatform: '上架平台',
      addToTheMall: '上架到商城',
      listedOnThirdPartyPlatforms: '上架到SAGE平台',
      removedFromThirdPartyPlatforms: '从SAGE平台下架',
      addProductsToTheMall: '上架产品到商城',
      removedFromTheMall: '从商城下架',
      removedFromThirdParties: '从SAGE下架',
      confirmToPutThisProductOnTheMall: '确认将此产品上架到商城',
      removedFromTheFollowingAlreadyOnTheShelvesMallPlatforms:
        '从以下“已上架”的商城平台中下架',
      removedFromTheFollowingAlreadyOnTheShelvesThirdPartyPlatforms:
        '从以下“已上架”的三方平台中下架',
      reasonForDelisting: '下架原因',
      KeywordAlreadyExists: '关键词不能重复',
      priceCodeTips: 'SAGE 平台上展示的为 List Price;',
      priceCodeTips2: '表示此 Option 的',
      priceCodeTips3: '表示此 Option 的金额为加价金额',
      priceCodeTips4: '表示此 Option 的金额为总价的金额',
      addOption: '新增 Option',
      productSold: '产品下架中。由于平台限制，请稍后查看下架状态',
    },
  },
  en: {
    productCenter: {
      Add: 'Add',
      AdvancedFilter: 'Advanced Search',
      AsLowAs: 'As Low As',
      AuditProgress: 'Audit Progress',
      Blacklist: 'Blacklist',
      Cancel: 'Cancel',
      Cc: 'Cc',
      Check: 'Check',
      ChineseName: 'Chinese Name',
      Clone: 'Clone',
      Close: 'Close',
      Code: 'Code',
      Confirm: 'Confirm',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CustomDisplay: 'Customize Display',
      CustomizedProduct: 'Customized Product',
      Delete: 'Delete',
      Deleted: 'Trash',
      Edit: 'Edit',
      EnglishName: 'Item Name',
      InReview: 'Pending Approval',
      InitiateApplication: 'Initiate Application',
      Inputting: 'Pending Submit',
      InquiryGeneration: 'Inquiry Generation',
      Inventory: 'Inventory',
      ListDraggableToSort: 'List Draggable To Sort',
      MajorSuppliers: 'Major Suppliers',
      ModifiedTime: 'Modification Time',
      NoAuditProgress: 'No Audit Progress',
      NoNeedToAudit: 'No Need To Audit',
      NumberOfEnquiries: 'Number of inquiries',
      NumberOfOrders: 'Number of orders',
      NumberOfStockInWarehouse: 'Number Of Stock In Warehouse',
      Operate: 'Operate',
      Operation: 'Operation',
      PassedTheReview: 'Approved',
      PendingReview: 'Pending Review',
      ReviewTransfer: 'Review Transfer',
      ProductCategory: 'Product Category',
      ProductCode: 'Item No.',
      ProductImage: 'Product Image',
      ProductOperations: 'Product owner',
      ProductSource: 'Product Origin',
      ProductType: 'Product Classification',
      ProductionLine: 'Production Line',
      ProductionSystem: 'Production System',
      ProductsSelected: '-- Products Selected',
      ReasonForRevocation: 'Reason For Revocation',
      RemainingStock: 'Remaining Stock',
      Reset: 'Reset',
      ReviewDismissed: 'Review Dismissed',
      Revoke: 'Revoke',
      RemovedFromShelves: 'Removed from shelves',
      Revoked: 'Revoked',
      SKUList: 'SKU List',
      Save: 'Save',
      Search: 'Search',
      SelfAdd: 'Self-Add',
      SpecificationDrawing: 'Specification Drawing',
      StandardProduct: 'Standard Product',
      Status: 'Status',
      MallStatus: 'Mall status',
      Submit: 'Submit',
      Supplier: 'Supplier',
      SureDelete: 'Are you sure to delete?',
      Tips: 'Tips',
      TransferOwnership: 'Transfer Ownership',
      Transferor: 'Transferor',
      CopySucceeded: 'CopySucceeded',
      SuccessfulTransferOfOwnership: 'SuccessfulTransferOfOwnership',
      AvailableStock: 'available stock',
      line100: 'One item per line, up to one hundred lines',
      Sage: 'SAGE',
      ESP: 'ESP',
      DC: 'DC',
      website: 'Official Website',
      selPlatForm: 'Select Platform',
      platstatus: 'Platform Status',
      WhetherTheCustoms:
        'Whether the customs declaration information is perfect',
      AllProducts: ' All Products',
      CustomsDeclarationNot:
        'Customs declaration information is not perfect(Audit status has been passed)',
      CustomsDeclarationHas:
        'Customs declaration information has been improved',
      ApprovalTime: 'Approval time',
      YouTubeVideo: 'YouTube video',
      all: 'All',
      added: 'Added',
      notAdded: 'Not Added',
      ViewVideo: 'View Video',
      listingPlatform: 'Listing Platform',
      addToTheMall: 'Add to the mall',
      listedOnThirdPartyPlatforms: 'Listed on SAGE platform',
      removedFromThirdPartyPlatforms: 'Removed from SAGE platform',
      addProductsToTheMall: 'Add products to the mall',
      removedFromTheMall: 'Removed from the mall',
      removedFromThirdParties: 'Removed from SAGE',
      confirmToPutThisProductOnTheMall:
        'Confirm to put this product on the mall',
      removedFromTheFollowingAlreadyOnTheShelvesMallPlatforms:
        'Removed from the following "already on the shelves" mall platforms',
      removedFromTheFollowingAlreadyOnTheShelvesThirdPartyPlatforms:
        'Removed from the following "already on the shelves" third-party platforms',
      reasonForDelisting: 'Reason for delisting',
      KeywordAlreadyExists: 'Keywords cannot be repeated',
      priceCodeTips: 'The price displayed on SAGE is the List Price',
      priceCodeTips2: 'This Option indicates',
      priceCodeTips3:
        'This Option indicates that the amount is upcharge amount.',
      priceCodeTips4:
        'This Option indicates that the amount is total price amount.',
      addOption: 'Add a new option',
      productSold: 'The product is removed from the shelves. Due to platform restrictions, please check the removal status later.'
    },
  },
}

export default translateConfig
