// [{
//   "id": "1061677572431069247",
//   "sellerProductId": "1061677572380737628",
//   "attributeName": "规格",
//   "attributeId": "1061677572418486352",
//   "attributeValue": "颜色",
//   "attributeValueEn": "Color",
//   "attributeType": "specifications",
//   "parentId": "0",
//   "sellerSort": 0,
//   "sellerVersion": 1,
//   "updateFlag": 0,
//   "sellerChild": [
//     {
//       "id": "1061677572435263573",
//       "sellerProductId": "1061677572380737628",
//       "attributeName": "规格值",
//       "attributeId": "1061677572418486352",
//       "attributeValue": "黑",
//       "attributeValueEn": "Black",
//       "attributeType": "specificationsValue",
//       "parentId": "1061677572431069247",
//       "sellerSort": 0,
//       "sellerVersion": 1,
//       "updateFlag": 1,
//       "sellerChild": null
//     },
//     {
//       "id": "1061677572435263574",
//       "sellerProductId": "1061677572380737628",
//       "attributeName": "规格值",
//       "attributeId": "1061677572418486352",
//       "attributeValue": "黄",
//       "attributeValueEn": "Yellow",
//       "attributeType": "specificationsValue",
//       "parentId": "1061677572431069247",
//       "sellerSort": 0,
//       "sellerVersion": 1,
//       "updateFlag": 1,
//       "sellerChild": null
//     },
//     {
//       "id": "1061677572435263575",
//       "sellerProductId": "1061677572380737628",
//       "attributeName": "规格值",
//       "attributeId": "1061677572418486352",
//       "attributeValue": "白",
//       "attributeValueEn": "White",
//       "attributeType": "specificationsValue",
//       "parentId": "1061677572431069247",
//       "sellerSort": 0,
//       "sellerVersion": 1,
//       "updateFlag": 1,
//       "sellerChild": null
//     }
//   ]
// }]
const state = () => ({
  productSpecsList: [], // 卖家中英文翻译规格配置
  specsList: [], // 规格列表
})
const getters = {
  productSpecsList: (state) => state.productSpecsList,
  specsList: (state) => state.specsList,
}
const mutations = {
  setProductSpecsList(state, productSpecsList) {
    state.productSpecsList = productSpecsList
  },
  setSpecsList(state, specsList) {
    state.specsList = specsList
  },
}

const actions = {
  setProductSpecsList({ commit }, productSpecsList) {
    commit('setProductSpecsList', productSpecsList)
  },
  setSpecsList({ commit }, specsList) {
    commit('setSpecsList', specsList)
  },
}
export default { state, getters, mutations, actions }
