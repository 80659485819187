import pubApi from '@/utils/call-api-data'
const state = () => ({
  locations: [],
  currencyList: [],
})
const getters = {}
const mutations = {
  setLocations(state, locations) {
    state.locations = locations
  },
  setCurrency(state, val) {
    state.currencyList = val || []
  },
}
const actions = {
  setLocations({ commit }, locations) {
    commit('setLocations', locations)
  },
  getCurrency({ commit }) {
    pubApi.financeSelect({ financeType: 1 }).then((res) => {
      if (res?.code == '000000') {
        commit('setCurrency', res.data)
      }
    })
  },
}
export default { state, getters, mutations, actions }
